import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Stack,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  TextField,
  ListSubheader,
  InputAdornment,
  IconButton,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import colors from "assets/theme/base/colors";
import MDButton from "components/MDButton";
import { useForm, Controller } from "react-hook-form";
import Union2 from "assets/images/Union2.png";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import ClearIcon from "@mui/icons-material/Clear";

const GenericReports = () => {
  let { textColor } = colors;
  let { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [filteredOptions, setFilteredOptions] = useState({});
  const [searchQuery, setSearchQuery] = useState({});
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [isReportSelected, setIsReportSelected] = useState(false);
  const [debouncedSearches, setDebouncedSearches] = useState({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      report: -1,
    },
  });

  const startDate = watch("start_date");
  const endDate = watch("end_date");
  const client_ids = watch("client_ids");
  const project_ids = watch("project_ids");
  const survey_ids = watch("survey_ids");
  const report = watch("report");

  const getReportList = async () => {
    try {
      setIsLoading(true);
      let data = await TLinkApi.get(`/reports/report-type-list`);
      setReportList(data.data);
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getReportParameters = async (reportId) => {
    try {
      setIsLoading(true);
      let response = await TLinkApi.get(`/reports/${reportId}/parameters`);
      const sortedParams = response.data.sort((a, b) => a.sort_order - b.sort_order);
      setParameters(sortedParams);
      setSelectedReportId(reportId);
      sortedParams.forEach((param) => {
        if (param.param_type_name === "Single Select" || param.param_type_name === "Multi Select") {
          if (
            param.param_key !== "start_date" &&
            param.param_key !== "end_date" &&
            param.param_key !== "project_ids" &&
            param.param_key !== "survey_ids" &&
            param.param_key !== "supplier_ids"
          ) {
            fetchDropdownData(param.param_key);
          }
        }
      });
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDropdownData = async (paramKey, search = "") => {
    let payload = {
      parameter_type: paramKey,
      filter: {
        client_ids: [],
        project_ids: [],
        survey_ids: [],
        supplier_ids: [],
        status_ids: [],
        sub_status_ids: [],
        qualification_category_type_ids: [],
        reconciliation_data_type_ids: [],
        reconciliation_type_ids: [],
      },
    };
    try {
      setIsLoading(true);
      let response = await TLinkApi.post(`/reports/generic-list?search=${search}`, payload);
      setDropdownOptions((prev) => ({
        ...prev,
        [paramKey]: response.data,
      }));
      setFilteredOptions((prev) => ({
        ...prev,
        [paramKey]: response.data, // Set initial filtered options to all options
      }));
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDropdownProjectData = async (paramKey, search = "") => {
    let payload = {
      parameter_type: paramKey,
      filter: {
        client_ids: client_ids,
        project_ids: [],
        survey_ids: [],
        supplier_ids: [],
        status_ids: [],
        sub_status_ids: [],
        qualification_category_type_ids: [],
        reconciliation_data_type_ids: [],
        reconciliation_type_ids: [],
      },
    };
    try {
      setIsLoading(true);
      let response = await TLinkApi.post(`/reports/generic-list?search=${search}`, payload);
      setDropdownOptions((prev) => ({
        ...prev,
        [paramKey]: response.data,
      }));
      setFilteredOptions((prev) => ({
        ...prev,
        [paramKey]: response.data, // Set initial filtered options to all options
      }));
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDropdownSurveyData = async (paramKey, search = "") => {
    let payload = {
      parameter_type: paramKey,
      filter: {
        client_ids: client_ids,
        project_ids: project_ids,
        survey_ids: [],
        supplier_ids: [],
        status_ids: [],
        sub_status_ids: [],
        qualification_category_type_ids: [],
        reconciliation_data_type_ids: [],
        reconciliation_type_ids: [],
      },
    };
    try {
      setIsLoading(true);
      let response = await TLinkApi.post(`/reports/generic-list?search=${search}`, payload);
      setDropdownOptions((prev) => ({
        ...prev,
        [paramKey]: response.data,
      }));
      setFilteredOptions((prev) => ({
        ...prev,
        [paramKey]: response.data, // Set initial filtered options to all options
      }));
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDropdownSupplierData = async (paramKey, search = "") => {
    let payload = {
      parameter_type: paramKey,
      filter: {
        client_ids: [],
        project_ids: [],
        survey_ids: survey_ids,
        supplier_ids: [],
        status_ids: [],
        sub_status_ids: [],
        qualification_category_type_ids: [],
        reconciliation_data_type_ids: [],
        reconciliation_type_ids: [],
      },
    };
    try {
      setIsLoading(true);
      let response = await TLinkApi.post(`/reports/generic-list?search=${search}`, payload);
      setDropdownOptions((prev) => ({
        ...prev,
        [paramKey]: response.data,
      }));
      setFilteredOptions((prev) => ({
        ...prev,
        [paramKey]: response.data, // Set initial filtered options to all options
      }));
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (report !== -1 && client_ids && client_ids.length > 0) {
      fetchDropdownProjectData("project_ids");
    } else if (report !== -1) {
      fetchDropdownData("project_ids");
    }
  }, [client_ids, report]);

  useEffect(() => {
    if (
      report !== -1 &&
      project_ids &&
      project_ids.length > 0 &&
      client_ids &&
      client_ids.length > 0
    ) {
      fetchDropdownSurveyData("survey_ids");
    } else if (report !== -1) {
      fetchDropdownData("survey_ids");
    }
  }, [project_ids, report]);

  useEffect(() => {
    if (report !== -1 && survey_ids && survey_ids.length > 0) {
      fetchDropdownSupplierData("supplier_ids");
    } else if (report !== -1) {
      fetchDropdownData("supplier_ids");
    }
  }, [survey_ids, report]);

  useEffect(() => {
    getReportList();
  }, []);

  const handleSearchChange = (paramKey, event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery((prev) => ({
      ...prev,
      [paramKey]: query,
    }));

    // Update debounced search state
    setDebouncedSearches((prev) => ({
      ...prev,
      [paramKey]: query,
    }));
  };

  // Add debounced search effect
  useEffect(() => {
    const delaySearchTimeouts = {};

    Object.entries(debouncedSearches).forEach(([paramKey, query]) => {
      if (query?.trim().length >= 3) {
        delaySearchTimeouts[paramKey] = setTimeout(() => {
          if (paramKey === "project_ids") {
            if (client_ids && client_ids.length > 0) {
              fetchDropdownProjectData(paramKey, query);
            } else {
              fetchDropdownData(paramKey, query);
            }
          } else if (paramKey === "survey_ids") {
            if (project_ids && project_ids.length > 0 && client_ids && client_ids.length > 0) {
              fetchDropdownSurveyData(paramKey, query);
            } else {
              fetchDropdownData(paramKey, query);
            }
          } else if (paramKey === "supplier_ids") {
            if (survey_ids && survey_ids.length > 0) {
              fetchDropdownSupplierData(paramKey, query);
            } else {
              fetchDropdownData(paramKey, query);
            }
          } else {
            fetchDropdownData(paramKey, query);
          }
        }, 1500);
      }
    });

    // Cleanup timeouts
    return () => {
      Object.values(delaySearchTimeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [debouncedSearches, client_ids, project_ids, survey_ids]);

  const save = async (formData) => {
    const filters = parameters.map((param) => ({
      name: param.param_key,
      value: formData[param.param_key] || "",
      sort_order: param.sort_order,
      fn_param_order: param.fn_param_order,
    }));

    const payload = {
      filters,
      report_id: selectedReportId,
    };

    try {
      setIsLoading(true);
      const response = await TLinkApi.post(`/reports/${selectedReportId}/data`, payload);
      enqueueSnackbar(response?.message, {
        variant: "success",
      });
      reset();
      setValue("report", -1);
      setIsReportSelected(false);
      setSelectedReportId(null);
      setParameters([]);
      setDropdownOptions({});
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const selectStyle = {
    height: "36.5px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const validateDateRange = (start, end) => {
    if (!start || !end) return true;
    const startDateObj = new Date(start);
    const endDateObj = new Date(end);
    const diffInDays = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));
    return diffInDays <= 60 || "The date range cannot exceed 60 days!";
  };

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
        <MDTypography fontSize="25px" color="black">
          Generic Reports
        </MDTypography>
      </Stack>

      <Grid container>
        <Grid item xs={12} md={12}>
          <hr style={{ border: `1px solid ${textColor.main}`, width: "100%" }} />
        </Grid>
      </Grid>

      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox mt={2} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
            size="small"
            disabled={!isReportSelected || isLoading}
          >
            <img
              src={Union2}
              width="15px"
              height="15px"
              style={{ paddingRight: "5px" }}
              alt="export"
            />
            Send Report
          </MDButton>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <MDTypography variant="subtitle2" color="black" gutterBottom>
              Select Report *
            </MDTypography>
            <Controller
              name="report"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth error={!!errors.report} size="small" sx={selectStyle}>
                  <Select
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);
                      getReportParameters(event.target.value);
                      setIsReportSelected(true); // Enable the Send Report button when a report is selected
                    }}
                  >
                    {reportList.length > 0 ? (
                      reportList.map((report) => (
                        <MenuItem key={report.id} value={report.id}>
                          {report.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No reports available</MenuItem>
                    )}
                  </Select>
                  <FormHelperText>{errors.report?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </Grid>

          {parameters && parameters.length > 0 ? (
            parameters.map((param) => (
              <Grid item xs={12} md={4} key={param.id}>
                <MDTypography variant="subtitle2" color="black" gutterBottom>
                  {param.display_name} {param.is_required && "*"}
                </MDTypography>
                <Controller
                  name={param.param_key}
                  control={control}
                  rules={{
                    required: param.is_required ? `${param.display_name} is required!` : false,
                    validate: (value) => {
                      // Validate the start_date and end_date fields
                      if (param.param_key === "start_date" && endDate) {
                        return (
                          validateDateRange(value, endDate) ||
                          "The date range cannot exceed 60 days!"
                        );
                      }
                      if (param.param_key === "end_date" && startDate) {
                        return (
                          validateDateRange(startDate, value) ||
                          "The date range cannot exceed 60 days!"
                        );
                      }
                      return true;
                    },
                  }}
                  render={({ field }) => {
                    if (param.param_key === "start_date" || param.param_key === "end_date") {
                      return (
                        <TextField
                          {...field}
                          sx={selectStyle}
                          type="date"
                          fullWidth
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={!!errors[param.param_key]}
                          helperText={errors[param.param_key]?.message}
                        />
                      );
                    }

                    switch (param.param_type_key) {
                      case "Single Select":
                      case "Multi Select":
                        return (
                          <FormControl
                            fullWidth
                            size="small"
                            error={!!errors[param.param_key]}
                            sx={selectStyle}
                          >
                            <Select
                              {...field}
                              multiple={param.param_type_key === "Multi Select"}
                              value={
                                param.param_type_key === "Multi Select"
                                  ? field.value || []
                                  : field.value || ""
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={(e) => {
                                      field.onChange([]);
                                      setSearchQuery((prev) => ({
                                        ...prev,
                                        [param.param_key]: "",
                                      }));
                                      if (param.param_key === "project_ids") {
                                        if (client_ids && client_ids.length > 0) {
                                          fetchDropdownProjectData(param.param_key);
                                        } else {
                                          fetchDropdownData(param.param_key);
                                        }
                                      } else if (param.param_key === "survey_ids") {
                                        if (
                                          project_ids &&
                                          project_ids.length > 0 &&
                                          client_ids &&
                                          client_ids.length > 0
                                        ) {
                                          fetchDropdownSurveyData(param.param_key);
                                        } else {
                                          fetchDropdownData(param.param_key);
                                        }
                                      } else if (param.param_key === "supplier_ids") {
                                        if (survey_ids && survey_ids.length > 0) {
                                          fetchDropdownSupplierData(param.param_key);
                                        } else {
                                          fetchDropdownData(param.param_key);
                                        }
                                      } else {
                                        fetchDropdownData(param.param_key);
                                      }
                                    }}
                                  >
                                    <ClearIcon size="small" />
                                  </IconButton>
                                </InputAdornment>
                              }
                              onChange={(event) => field.onChange(event.target.value)}
                              onClose={() => {
                                // Clear the search query when dropdown closes
                                setSearchQuery((prev) => ({
                                  ...prev,
                                  [param.param_key]: "",
                                }));
                              }}
                            >
                              <ListSubheader sx={{ marginBottom: "1rem" }}>
                                <TextField
                                  sx={{
                                    position: "sticky",
                                    marginTop: "-16px",
                                    paddingTop: "1rem",
                                    zIndex: 1,
                                    background: "white",
                                  }}
                                  placeholder="Type to search..."
                                  size="small"
                                  autoFocus
                                  fullWidth
                                  value={searchQuery[param.param_key] || ""}
                                  onChange={(event) => handleSearchChange(param.param_key, event)}
                                  onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                      e.stopPropagation();
                                    }
                                  }}
                                />
                              </ListSubheader>
                              {filteredOptions[param.param_key]?.length > 0 ? (
                                filteredOptions[param.param_key].map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>No options available</MenuItem>
                              )}
                            </Select>

                            <FormHelperText>{errors[param.param_key]?.message}</FormHelperText>
                          </FormControl>
                        );
                      default:
                        return (
                          <TextField
                            {...field}
                            fullWidth
                            size="small"
                            error={!!errors[param.param_key]}
                            helperText={errors[param.param_key]?.message}
                          />
                        );
                    }
                  }}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <MDTypography variant="body2" color="textSecondary">
                No reports selected.
              </MDTypography>
            </Grid>
          )}
        </Grid>
      </form>
    </MDBox>
  );
};

export default GenericReports;
