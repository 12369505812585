import Grid from "@mui/material/Grid";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  ListSubheader,
  TextField,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetStudyTypeList from "hooks/StudyTypeList/GetStudyTypeList";
import GetProjectManagerList from "hooks/ProjectManagerList/GetProjectManagerList";
import DetailIcon from "assets/images/Detail.svg";
import CloneModal from "../CloneModal";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";

function BasicDetails() {
  const navigate = useNavigate();
  // const { language, languageMap } = GetLanguageList();
  const { isEdit, setIsEdit, project, getData, isManual } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const { clients } = GetListOfClients();
  const [isLoading, setIsLoading] = useState(false);
  const { studyType } = GetStudyTypeList();
  const { projectManager, setManagerSearch, managerSearch } = GetProjectManagerList();
  const [status, setStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [statusSearch, setStatusSearch] = useState("");
  const [searchStatusList, setSearchStatusList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [searchStudyType, setSearchStudyType] = useState([]);
  const [searchFilteredProjectTypes, setSearchFilteredProjectTypes] = useState([]);
  const [filteredProjectTypes, setFilteredProjectTypes] = useState([]);
  const [searchClient, setSearchClient] = useState("");
  const [searchClientList, setSearchClientList] = useState([]);

  const [cloneOpen, setCloneOpen] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      name: "",
      clientId: "",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().add(15, "days").format("YYYY-MM-DD"),
      projectManager: "",
      status: "1",
      projectTypeId: "1",
    },
  });

  let startDate = watch("startDate");
  let projectStatus = watch("status");

  let { id } = useParams();

  useEffect(() => {
    if (Object.keys(project).length > 0) {
      setValue("name", project.name ? project.name : "");
      setValue("projectManager", project.projectManager ? project.projectManager : "");
      setValue(
        "startDate",
        project.startDate
          ? moment(project.startDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD")
      );
      setValue(
        "endDate",
        project.endDate
          ? moment(project.endDate).format("YYYY-MM-DD")
          : moment().add(15, "days").format("YYYY-MM-DD")
      );
      setValue("status", project.status ? project.status : "1");
      setValue("clientId", project.clientId ? project.clientId : "");
      setValue("projectTypeId", project.projectTypeId ? project.projectTypeId : "1");
    }
  }, [project]);

  useEffect(() => {
    setSearchClientList(clients);
  }, [clients]);

  useEffect(() => {
    let searchData = clients?.filter((data) => {
      return data?.name.toLowerCase().includes(searchClient?.toLowerCase());
    });
    setSearchClientList(searchData);
  }, [searchClient]);

  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  useEffect(() => {
    let searchStatusData = statusList.filter((data) => {
      return data.name.toLowerCase().includes(statusSearch.toLowerCase());
    });
    setSearchStatusList(searchStatusData);
  }, [statusSearch, statusList]);

  useEffect(() => {
    let objectList = [
      { id: 1, name: "Awarded" },
      { id: 2, name: "Live" },
      { id: 3, name: "Pause/On hold" },
      { id: 4, name: "Completed" },
      { id: 5, name: "Reconciled" },
      { id: 6, name: "Ready to invoice" },
      { id: 7, name: "Closed" },
      { id: 8, name: "Permanent Closed" },
      { id: 9, name: "System Closed" },
    ];
    setStatusList(objectList);
  }, [status]);

  useEffect(() => {
    setSearchStudyType(studyType);
  }, [studyType]);

  useEffect(() => {
    setSearchFilteredProjectTypes(filteredProjectTypes);
  }, [filteredProjectTypes]);

  useEffect(() => {
    let searchStudyTypeData = studyType?.filter((data) => {
      return data?.name.toLowerCase().includes(search?.toLowerCase());
    });
    let searchFilteredProjectTypesData = filteredProjectTypes?.filter((data) => {
      return data?.name.toLowerCase().includes(search?.toLowerCase());
    });
    setSearchStudyType(searchStudyTypeData);
    setFilteredProjectTypes(searchFilteredProjectTypesData);
  }, [search]);

  const save = async (data) => {
    setIsLoading(true);
    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/projects/${id}`, data);
        setIsEdit({
          disabled: false,
          edit: false,
        });
        getData();
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/projects/${id}`);
      } else {
        let result = await TLinkApi.post("/projects", data);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: false,
        });
        getData();
        navigate(`/projects/${result.data.id}/surveyList`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  const handleClose = () => {
    setCloneOpen(false);
  };

  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CloneModal
        open={cloneOpen}
        handleClose={handleClose}
        // defaultLanguageId={watch("languageId")}
        // language={language}
        // languageMap={languageMap}
      />
      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox
          p={2}
          px={20}
          style={{ width: "100%", height: `calc(100vh - 252px)`, overflowY: "auto" }}
          mt={2}
        >
          <Stack
            justifyContent={"space-between"}
            alignItems="flex-end"
            sx={{ width: "100%" }}
            mb={1}
            direction={isEdit.edit ? "row" : ""}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <img src={DetailIcon} />
              <Typography fontWeight="600" color="primary">
                Basic Details
              </Typography>
            </Stack>

            {isEdit.disabled !== false ? (
              <MDBox display="flex" alignItems="center" gap="10px">
                <IconButton
                  color="dark"
                  size="small"
                  onClick={() => {
                    navigate(`/projects/${id}/edit`);
                    setIsEdit({
                      disabled: false,
                      edit: true,
                    });
                  }}
                >
                  <Icon>border_color_icon</Icon>
                </IconButton>
                <Tooltip title="Clone Survey" placement="top">
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={() => {
                      setCloneOpen(true);
                    }}
                  >
                    clone
                  </MDButton>
                </Tooltip>
              </MDBox>
            ) : (
              <MDBox display="flex" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    if (window.location.pathname === "/projects/new") {
                      navigate(`/projects`);
                    } else {
                      getData();
                      navigate(`/projects/${id}`);
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  Save
                </MDButton>
              </MDBox>
            )}
          </Stack>

          {isEdit.edit || window.location.pathname === "/projects/new" ? (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {label("Client Id")}
                <Controller
                  name="clientId"
                  control={control}
                  rules={{
                    required: "Client is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.clientId ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <Select
                        value={value}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={selectStyle}
                        disabled={!isManual}
                        onClose={() => setSearchClient("")}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            value={searchClient}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setSearchClient(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {searchClientList?.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.clientId && (
                        <FormHelperText>
                          {errors?.clientId ? errors.clientId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {label("Project Title")}
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: "Project Title is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      disabled={isEdit.disabled}
                      autoComplete="off"
                      variant="outlined"
                      placeholder="Project Title"
                      error={errors?.name ? true : false}
                      helperText={errors?.name ? errors.name.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {label("Project Type")}
                <Controller
                  name="projectTypeId"
                  control={control}
                  // rules={{
                  //   required: "Project Type is required!",
                  // }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.projectTypeId ? true : false}
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                      size="small"
                    >
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={(e) => setValue("projectTypeId", e.target.value)}
                        sx={selectStyle}
                        onClose={() => setSearch("")}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            value={search}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {[...searchFilteredProjectTypes, ...searchStudyType].map((value, i) => {
                          return (
                            <MenuItem key={i} value={value.id}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.projectTypeId && (
                        <FormHelperText>
                          {errors?.projectTypeId ? errors.projectTypeId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {label("Project Manager")}
                <Controller
                  name="projectManager"
                  control={control}
                  rules={{
                    required: "Project Manager is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.projectManager ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <Select
                        value={value}
                        onChange={onChange}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={selectStyle}
                        disabled={!isManual}
                        onClose={() => setManagerSearch("")}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={managerSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setManagerSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {projectManager?.map((value, i) => {
                          return (
                            <MenuItem value={value.id} key={i}>
                              {value.fullName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors?.projectManager && (
                        <FormHelperText>
                          {errors?.projectManager ? errors.projectManager.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {label("Start date")}
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: "startDate is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="Date"
                      disabled={isEdit.disabled}
                      error={errors?.startDate ? true : false}
                      helperText={errors?.startDate ? errors.startDate.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {label("End Date")}
                <Controller
                  name="endDate"
                  control={control}
                  rules={{
                    required: "End Date is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      autoComplete="off"
                      variant="outlined"
                      type="Date"
                      disabled={isEdit.disabled || startDate === ""}
                      {...register("endDate", {
                        min: startDate ? new Date(startDate).toISOString().split("T")[0] : "",
                      })}
                      error={errors?.endDate ? true : false}
                      helperText={
                        errors?.endDate && errors.endDate.message !== ""
                          ? errors.endDate.message
                          : errors?.endDate?.type === "min"
                          ? "End date should not be less than Start date"
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {label("Status")}
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.status ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: "rgba(0, 0, 0, 0.25)",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        sx={selectStyle}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onClose={() => setStatusSearch("")}
                      >
                        <ListSubheader sx={{ marginBottom: "1rem" }}>
                          <TextField
                            sx={{
                              position: "sticky",
                              marginTop: "-16px",
                              paddingTop: "1rem",
                              zIndex: 1,
                              background: "white",
                            }}
                            size="small"
                            autoFocus
                            required
                            value={statusSearch}
                            placeholder="Type to search..."
                            fullWidth
                            onChange={(e) => setStatusSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {searchStatusList?.map((status) => (
                          <MenuItem value={status.id}>{status.name}</MenuItem>
                        ))}
                      </Select>
                      {errors?.status && (
                        <FormHelperText>
                          {errors?.status ? errors.status.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1.8}>
              <Grid item xs={12} md={4}>
                {label("Client Id")}
                <Typography fontSize="14px" fontWeight="600">
                  {clients?.find((value) => Number(value.id) === Number(watch("clientId")))?.name}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                {label("Project Title")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("name")}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                {label("Project Type")}
                <Typography fontSize="14px" fontWeight="600">
                  {
                    studyType?.find((value) => Number(value.id) === Number(watch("projectTypeId")))
                      ?.name
                  }
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                {label("Project Manager")}
                <Typography fontSize="14px" fontWeight="600">
                  {
                    projectManager?.find(
                      (value) => Number(value.id) === Number(watch("projectManager"))
                    )?.fullName
                  }
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                {label("Start Date", true)}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("startDate") &&
                    new Date(watch("startDate")).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("End Date", true)}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("endDate") &&
                    new Date(watch("endDate")).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Status")}
                <Typography fontSize="14px" fontWeight="600">
                  {projectStatus == 1
                    ? "Awarded"
                    : projectStatus == 2
                    ? "Live"
                    : projectStatus == 3
                    ? "Pause / On hold"
                    : projectStatus == 4
                    ? "Completed"
                    : projectStatus == 5
                    ? "Reconciled"
                    : projectStatus == 6
                    ? "Ready to invoice"
                    : projectStatus == 7
                    ? "Closed"
                    : projectStatus == 8
                    ? "Permanent Closed"
                    : projectStatus == 9
                    ? "System Closed"
                    : "NA"}
                </Typography>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </form>
    </MDBox>
  );
}

export default BasicDetails;
