import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import useTableTheme from "constant/TableTheme";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { ThemeProvider } from "@mui/material";
import MDTypography from "components/MDTypography";
import TLinkApi from "services/tlink.api";
import moment from "moment/moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MDButton from "components/MDButton";

function RespondentStatus() {
  let { id } = useParams();

  const tableTheme = useTableTheme();
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  const [supplierList, setSupplierList] = useState([]);
  const [supplierModeList, setSupplierModeList] = useState([]);

  const [surveyRespondent, setSurveyRespondent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortBy, setSortBy] = useState("status");
  const [sortOrder, setSortOrder] = useState("asc");
  const [suppliers, setSuppliers] = useState(-1);
  const [supplierMode, setSupplierMode] = useState(-1);
  const [timeline, setTimeLine] = useState({
    endDate: moment().subtract(0, "days").endOf("day").format("YYYY-MM-DD"),
    startDate: moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
  });

  const [filters, setFilters] = useState({
    suppliers: -1,
    supplierMode: -1,
    timeline: {
      endDate: moment().subtract(0, "days").endOf("day").format("YYYY-MM-DD"),
      startDate: moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
    },
  });

  const getSurveyRespondent = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post(
        `/survey/${id}/respondents/status?sortBy=${sortBy}&sortOrder=${sortOrder}&page=${
          page + 1
        }&limit=${entriesPerPage}&supplierId=${filters.suppliers}&status=${filters.supplierMode}`,
        filters.timeline
      );
      setSurveyRespondent(data.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSurveyRespondent();
  }, [id, sortBy, sortOrder, entriesPerPage, page, suppliers, supplierMode, timeline]);

  useEffect(() => {
    if (Object.keys(timeline)?.length > 0) {
      getSurveyRespondent();
    }
  }, [timeline]);

  const getSupplierList = async () => {
    try {
      let data = await TLinkApi.post(
        `/survey/${id}/supplier/?supplierId=-1&supplierModeId=-1&isProgrammaticFilter=-1`,
        {
          endDate: "",
          startDate: "",
        }
      );
      setSupplierList(
        data?.map((supplier) => {
          return { supplierId: supplier.supplierId, supplierName: supplier.supplierName };
        })
      );
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let getSupplierModeData = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/suppliermode`);
      setSupplierModeList(result.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getSupplierModeData();
    getSupplierList();
  }, []);

  useEffect(() => {
    setData(surveyRespondent);
  }, [surveyRespondent]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("status");
      setSortOrder("asc");
    }
  }, [pagination, sorting]);

  let columns = [
    {
      accessorKey: "status",
      header: "Status",
      align: "center",
      size: 150,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "subStatus",
      header: "Respondent Sub Status",
      align: "center",
      size: 150,
    },
    {
      accessorKey: "count",
      header: "Count of Records",
      align: "center",
      size: 150,
    },
    {
      accessorKey: "countPercentage",
      header: "Count(%)",
      enableSorting: false,
      align: "center",
      size: 150,
      Cell: ({ cell }) => {
        return parseFloat(cell.getValue()).toFixed(2);
      },
    },
  ];

  const table = useMaterialReactTable({
    columns,
    data,
    enableDensityToggle: false,
    enableColumnPinning: true,
    enableStickyHeader: true,
    manualSorting: true,
    manualPagination: true,
    state: { density: "compact", pagination, sorting },
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 365px)",
        overflowY: "auto",
      },
    },
    onPaginationChange: setPagination,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    onSortingChange: setSorting,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  const ranges = {
    Today: [moment().subtract(1, "days").startOf("day"), moment().subtract(0, "days").endOf("day")],
    "Last Week": [
      moment().subtract(7, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
    "Last Month": [
      moment().subtract(30, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
    "Last Year": [
      moment().subtract(365, "days").startOf("day"),
      moment().subtract(0, "days").endOf("day"),
    ],
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        px={0}
        item
        xs={6}
        md={6}
        mt={-1}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-end", md: "center" },
        }}
        gap="15px"
      >
        <MDTypography color="black">Survey Respondent Table</MDTypography>
      </Grid>
      <Grid container alignItems="center" spacing={1} sx={{ width: "800px" }} mt={0.5}>
        <Grid item xs={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Suppliers</InputLabel>
            <Select
              value={filters.suppliers}
              label="Suppliers"
              sx={selectStyle}
              onChange={(e) => setFilters((prev) => ({ ...prev, suppliers: e.target.value }))}
            >
              <MenuItem value={-1}>All</MenuItem>
              {supplierList.map((supplier) => (
                <MenuItem key={supplier.supplierId} value={supplier.supplierId}>
                  {supplier.supplierName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Supplier Mode</InputLabel>
            <Select
              value={filters.supplierMode}
              label="Supplier Mode"
              sx={selectStyle}
              onChange={(e) => setFilters((prev) => ({ ...prev, supplierMode: e.target.value }))}
            >
              <MenuItem value={-1}>All</MenuItem>
              {supplierModeList.map((mode, i) => (
                <MenuItem value={mode.id} key={i}>
                  {mode.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth size="small">
            <DateRangePicker
              initialSettings={{
                endDate: moment().subtract(0, "days").endOf("day"),
                startDate: moment().subtract(1, "days").startOf("day"),
                ranges: ranges,
              }}
              onCallback={(start, end) => {
                // Update the filters state with the correct date format
                setFilters((prev) => ({
                  ...prev,
                  timeline: {
                    startDate: moment(start._d).format("YYYY-MM-DD"),
                    endDate: moment(end._d).format("YYYY-MM-DD"),
                  },
                }));
              }}
            >
              <MDBox position="relative">
                <MDInput
                  ml={2}
                  color="text"
                  inputProps={{
                    style: {
                      height: "20px",
                      border: "2px solid #D9D9D9",
                    },
                  }}
                  size="large"
                  fullWidth
                  value={
                    moment(filters.timeline?.startDate).format("LL") +
                    "  -  " +
                    moment(filters.timeline?.endDate).format("LL")
                  }
                />
                <KeyboardArrowDownIcon
                  fontSize="small"
                  sx={{
                    fontSize: 20,
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "12px",
                  }}
                />
              </MDBox>
            </DateRangePicker>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <MDButton
            variant="contained"
            color="info"
            size="small"
            onClick={() => {
              getSurveyRespondent();
            }}
          >
            Apply Filter
          </MDButton>
        </Grid>
      </Grid>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          table={table}
          style={{
            width: "100%",
          }}
        />
      </ThemeProvider>
    </>
  );
}

export default RespondentStatus;
