import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import QuestionList from "./components/QuestionList";
import QuotaOverview from "./components/QuotaOverview";
import Label from "components/InputLabel";
import { Controller, useForm } from "react-hook-form";
import MDInput from "components/MDInput";
import GetCalculationList from "hooks/Calculation/GetCalculationList";
import { useSnackbar } from "notistack";
import TLinkApi from "services/tlink.api";
import { useOutletContext, useParams } from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { EditOutlined } from "@mui/icons-material";
import QuotaIcon from "assets/images/quotaIcon.svg";
import QuotaListIcon from "assets/images/quotaListIcon.svg";
import { useCallbackPrompt } from "hooks/useCallbackPrompt";

const NestedQuota = ({ setNestedQuota, nestedQuota }) => {
  let { calculationList } = GetCalculationList();
  const [qualification, setQualification] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [finalRows, setFinalRows] = useState([]);
  const [getQuotaData, setGetQuotaData] = useState([]);
  const [addData, setAddData] = useState(true);
  const [calculationId, setCalculationId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [qualOpen, setQualOpen] = useState(false);
  const [getQual, setGetQual] = useState(false);
  const [quotaSumError, setQuotaSumError] = useState(false);
  const [quotaSurveyError, setQuotaSurveyError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [proceedData, setProceedData] = useState(false);
  const [warning, setWarning] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(warning);
  const open = Boolean(anchorEl);

  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();
  let { getData, survey } = useOutletContext();

  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      target: 0,
      fillTarget: 1,
      missingTarget: 0,
    },
  });

  const changeCalculation = async (value) => {
    setIsLoading(true);
    try {
      await TLinkApi.patch(`/survey/${id}`, {
        sampleCalculationType: calculationId,
        fillTarget: watch("fillTarget"),
        target: watch("target"),
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Oopss! somthig went wrong", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (survey.sampleCalculationType) {
      setCalculationId(survey.sampleCalculationType);
    }
  }, [survey]);

  useEffect(() => {
    if (finalRows.length > 0) {
      let count = 0;
      finalRows
        .filter((val) => val.Actions)
        .map((val) => {
          count = Number(count) + (Number(val.Quota) ? Number(val.Quota) : 0);
        });

      if (count === 0) {
        setValue("target", survey.sampleRequired);
      } else {
        setValue("target", Number(count));
      }
    } else if (survey.sampleRequired) {
      setValue("target", Number(survey.sampleRequired));
    }
  }, [finalRows, survey]);

  useEffect(() => {
    let count = 0;
    finalRows.map((val) => {
      count = Number(count) + (Number(val.Remaining) ? Number(val.Remaining) : 0);
    });
    setValue("missingTarget", count);
  }, [finalRows]);

  useEffect(() => {
    let count = 0;
    finalRows
      .filter((val) => val.Actions)
      .map((val) => {
        count = count + (Number(val.Quota) ? Number(val.Quota) : 0);
      });
    if (count > Number(watch("target"))) {
      setQuotaSumError(true);
    } else {
      setQuotaSumError(false);
    }
  }, [finalRows, watch("target")]);

  const save = async () => {
    let payload = JSON.parse(JSON.stringify(finalRows));
    let localdata = payload.map((val) => {
      delete val.Conversion;
      delete val.Completes;
      delete val.Starts;
      let localQuota = val.conditions
        .filter((value) => value.qualificationId)
        .map((value) => {
          if (value.answerId) {
            delete value.answerText;
          }
          return value;
        });
      let finalConditionPayload = {
        isActive: val.Actions,
        name: "",
        sampleNumber: addData || proceedData ? val.Quota : val.Remaining,
        conditions: localQuota,
        sampleNumber: val.Quota,
      };

      val.quota = finalConditionPayload;
      if (val.id > 0) {
        val.quota.id = val.id;
      }
      return val.quota;
    });

    let finalPayload = {
      surveyId: id,
      quota: localdata,
    };
    setIsLoading(true);
    try {
      if (addData) {
        await TLinkApi.post(`/survey/quota`, finalPayload);
      } else {
        await TLinkApi.put(`/survey/${id}/quota`, finalPayload);
      }
      await changeCalculation(calculationId);
      await getData();
      await getQualificationList();
      await getQuotasList();
      enqueueSnackbar("Quota is saved successfully", {
        variant: "success",
      });
      setIsLoading(false);
      setProceedData(false);
      setWarning(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Oopss! somthig went wrong", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  function fillTargets(quotas, noOfTargets) {
    let length = quotas.length;
    let zp = length - (noOfTargets % length);
    let pp = Math.floor(noOfTargets / length);
    let i = 0;
    for (let surveyQuota of quotas) {
      if (i >= zp) {
        if (watch("fillTarget") === 1) {
          surveyQuota.quota.sampleNumber = pp + 1;
        } else {
          surveyQuota.quota.sampleNumber = pp + 1;
        }
        if (addData || proceedData) {
          surveyQuota.quota.sampleNumber = pp + 1;
        }
      } else {
        if (watch("fillTarget") === 1) {
          surveyQuota.quota.sampleNumber = pp;
        } else {
          surveyQuota.quota.sampleNumber = pp;
        }
        if (addData || proceedData) {
          surveyQuota.quota.sampleNumber = pp;
        }
      }
      i++;
    }
    return quotas;
  }

  let getQuotasList = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`survey/${id}/quota`);
      if (result.length > 0) {
        setGetQuotaData(result);
        setAddData(false);
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let getQualificationList = async () => {
    try {
      let result = await TLinkApi.get(`survey/${id}/qualification/conditions`);
      let tempQualificationQuestions = result?.qualifications.map((qualification) => {
        qualification.checked = false;
        if (qualification.qualificationTypeId === 4) {
          let ranges = qualification.answerText.map((value) => {
            let rangeValue = value?.split("-");
            if (rangeValue?.length > 0) {
              let payload = {
                min: parseInt(rangeValue[0]),
                max: parseInt(rangeValue[1]),
              };
              return payload;
            }
          });
          qualification.range = ranges;
          qualification.selectedRange = ranges;
          qualification.selectedRangeCheck = [];
        } else if (qualification.qualificationTypeId === 3) {
          qualification.text = qualification?.answerText;
          qualification.selectedText = qualification?.answerText;
          qualification.selectedTextCheck = [];
        } else {
          qualification.selectedAnswers = qualification.answerId;
          qualification.selectedAnsCheck = [];
        }
        delete qualification.answerText;
        delete qualification.answerId;

        return qualification;
      });
      tempQualificationQuestions.sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder));
      await setQualification(tempQualificationQuestions);
    } catch (e) {
      console.log(e);
    }
  };

  const callApi = async () => {
    setIsLoading(true);
    await getQualificationList();
    await getQuotasList();
  };

  useEffect(() => {
    if (id) {
      callApi();
    }
  }, [id]);

  useEffect(() => {
    let idsArray = [];
    getQuotaData.map((val) =>
      val.quota.conditions.map((value) => {
        if (!idsArray.includes(Number(value.qualificationId))) {
          idsArray.push(value.qualificationId);
        }
      })
    );

    let createColumns = qualification
      .filter((val) => idsArray.includes(Number(val.qualificationId)))
      .map((val, index) => {
        return {
          id: Number(val.qualificationId),
          numeric: false,
          disablePadding: index === 0 ? true : false,
          label: val.question.questionSubText
            ? val.question.questionSubText
            : val.question.questionText,
        };
      });
    let uniqueColumn = [
      {
        id: "sampleNumber",
        numeric: true,
        disablePadding: false,
        label: "Quota",
      },
      {
        id: "starts",
        numeric: true,
        disablePadding: false,
        label: "Starts",
      },
      {
        id: "completes",
        numeric: true,
        disablePadding: false,
        label: "Completes",
      },
      {
        id: "conversion",
        numeric: true,
        disablePadding: false,
        label: "Conversion",
      },
      {
        id: "remaining",
        numeric: true,
        disablePadding: false,
        label: "Remaining",
      },
      {
        id: "isActive",
        numeric: true,
        disablePadding: false,
        label: "Actions",
      },
    ];

    let copyArray = JSON.parse(JSON.stringify(getQuotaData));
    let localData = copyArray.map((val, index) => {
      val.quota.conditions.map((value) => {
        let findQual = qualification.find(
          (ques) => Number(value.qualificationId) === Number(ques.qualificationId)
        );
        if (findQual?.qualificationTypeId === 1 || findQual?.qualificationTypeId === 2) {
          // let ansText = "";
          // let findQues = qualification.find(
          //   (ques) => Number(value.qualificationId) === Number(ques.qualificationId)
          // )?.question;
          // if (findQues) {
          //   ansText = findQues.answers.find(
          //     (ans) => Number(value?.answerId[0]) === Number(ans.id)
          //   )?.qualificationAnswerDesc;
          //   if (ansText) {
          //     value.answerText = [ansText];
          //   }
          // }
          let ansTextArray = [];
          let findQues = qualification.find(
            (ques) => Number(value.qualificationId) === Number(ques.qualificationId)
          )?.question;

          if (findQues) {
            ansTextArray = value.answerId
              .map((answerId) => {
                return (
                  findQues.answers.find((ans) => Number(answerId) === Number(ans.id))
                    ?.qualificationAnswerDesc || ""
                );
              })
              .filter((ansText) => ansText !== "");

            if (ansTextArray.length > 0) {
              value.answerText = ansTextArray;
            }
          }
        } else {
          delete value.answerId;
        }
        return value;
      });
      return val;
    });

    const newColumn = createColumns.concat(uniqueColumn);
    setColumns(newColumn);

    if (qualification.length > 0) {
      setRows(localData);
      setTimeout(() => {
        setGetQual(true);
      }, 200);
    }
  }, [getQuotaData]);

  useEffect(() => {
    if (getQual) {
      let idsArray = [];
      getQuotaData.map((val) =>
        val.quota.conditions.map((value) => {
          if (!idsArray.includes(Number(value.qualificationId))) {
            idsArray.push(value.qualificationId);
          }
        })
      );

      let payload = [...qualification];
      payload.map((val) => {
        let answerId = [];
        let answerText = [];
        getQuotaData.map((quota) => {
          quota.quota.conditions
            .filter((value) => Number(value.qualificationId) === Number(val.qualificationId))
            .map((value) => {
              if (value?.answerId?.length > 0) {
                if (!value?.answerId.every((id) => answerId.includes(Number(id)))) {
                  answerId.push(...value.answerId.filter((id) => !answerId.includes(Number(id))));
                }
              } else {
                if (!value?.answerText.every((text) => answerText.includes(Number(text)))) {
                  answerText.push(
                    ...value.answerText.filter((text) => !answerText.includes(Number(text)))
                  );
                }
                // if (!answerText.includes(value?.answerText[0])) {
                //   answerText.push(value?.answerText[0]);
                // }
              }
            });
        });
        if (val.qualificationTypeId === 1 || val.qualificationTypeId === 2) {
          val.selectedAnswers = val.selectedAnswers;
          val.selectedAnsCheck = answerId.length > 0 ? answerId : val.selectedAnsCheck;
        } else if (val.qualificationTypeId === 4) {
          let localRange = val.range.map((rangeVal) => {
            return `${rangeVal.min}-${rangeVal.max}`;
          });

          let selectedIndex = [];
          answerText.map((ansText) => {
            if (!localRange.includes(ansText)) {
              localRange.push(ansText);
            }
          });
          localRange.map((ansText, index) => {
            if (answerText.includes(ansText)) {
              if (!selectedIndex.includes(index)) {
                selectedIndex.push(index);
              }
            }
          });

          val.range = localRange.map((value) => {
            let rangeValue = value?.split("-");
            if (rangeValue?.length > 0) {
              let payload = {
                min: parseInt(rangeValue[0]),
                max: parseInt(rangeValue[1]),
              };
              return payload;
            }
          });
          val.selectedRange = val.range;
          val.selectedRangeCheck = selectedIndex;
        } else {
          let selectedTextIndex = [];
          answerText.map((ansText) => {
            if (!val.text.includes(ansText)) {
              val.text.push(ansText);
            }
          });

          val?.text?.map((ansText, index) => {
            if (answerText.includes(ansText)) {
              if (!selectedTextIndex.includes(index)) {
                selectedTextIndex.push(index);
              }
            }
          });
          val.selectedText = val.text;
          val.selectedTextCheck = selectedTextIndex;
        }
        if (idsArray.includes(val.qualificationId)) {
          val.checked = true;
        }
        return val;
      });
      setQualification(payload);
      if (qualification.length > 0) {
        setGetQual(false);
        setIsLoading(false);
      }
    }
  }, [getQuotaData, getQual]);

  useEffect(() => {
    if (addData) {
      setQualOpen(true);
    } else {
      setQualOpen(false);
    }
  }, [addData, isEdit]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const QuotaActiveDeactive = async (isActive, quotaId) => {
    setIsLoading(true);
    try {
      await TLinkApi.patch(`/survey/${id}/quota`, {
        quotaIds: [quotaId],
        isActive: isActive,
      });
      await getQuotasList();
      enqueueSnackbar(`Quota ${isActive ? "active" : "deactive"} successfully`, {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`${e?.response?.data?.message}`, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  // quota group active deactive api
  const QuotaGroupActiveDeactive = async (isActive, quotaIds) => {
    setIsLoading(true);
    try {
      await TLinkApi.patch(`/survey/${id}/quota`, {
        quotaIds: quotaIds,
        isActive: isActive,
      });
      await getQuotasList();
      enqueueSnackbar(`Quotas ${isActive ? "active" : "deactive"} successfully`, {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`${e?.response?.data?.message}`, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const [refreshed, setRefreshed] = useState(false);

  useEffect(() => {
    if (proceedData) {
      setRefreshed(true);
      setWarning(true);
    }
  }, [proceedData]);

  useEffect(() => {
    if (refreshed) {
      setTimeout(() => {
        setRefreshed(false);
      }, 200);
    }
  }, [refreshed]);

  window.onbeforeunload = function () {
    if (warning) {
      return "You have unsaved changes. Please save them before leaving the page.";
    }
  };

  useEffect(() => {
    if (showPrompt) {
      if (
        window.confirm("You have unsaved changes. Please save them before leaving the page.") ==
        true
      ) {
        cancelNavigation();
      } else {
        confirmNavigation();
      }
    }
  }, [showPrompt]);

  return (
    <form onSubmit={handleSubmit(save)} noValidate>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontWeight="600">Quota</Typography>
        {isEdit.edit || addData ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Tooltip title="Edit Quota Qualifications" placement="top">
              <MDButton
                color="dark"
                variant="text"
                sx={{ display: !qualOpen && (addData || isEdit.edit) ? "" : "none" }}
                size="small"
                startIcon={<EditOutlinedIcon sx={{ height: "1.5rem !important" }} />}
                onClick={() => {
                  setQualOpen(true);
                  setProceedData(false);
                }}
              >
                Qualification
              </MDButton>
            </Tooltip>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={nestedQuota}
                  onChange={(e) => {
                    setNestedQuota(e.target.checked);
                  }}
                />
              }
              label="Nested Quota"
            />
            <MDButton
              color="secondary"
              variant="contained"
              size="small"
              sx={{ display: addData || isEdit.edit ? "" : "none" }}
              onClick={async () => {
                if (addData) {
                  setRows([]);
                  setGetQuotaData([]);
                  await getQualificationList();
                  await getData();
                } else {
                  await callApi();
                  await getData();
                }
                setQualOpen(false);
                setProceedData(false);
                setWarning(false);
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              color="info"
              variant="contained"
              size="small"
              type="submit"
              disabled={rows.length <= 0 || quotaSumError || quotaSurveyError}
              sx={{ display: addData || isEdit.edit ? "" : "none" }}
            >
              Save
            </MDButton>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  size="small"
                  checked={nestedQuota}
                  onChange={(e) => {
                    setNestedQuota(e.target.checked);
                  }}
                />
              }
              label="Nested Quota"
            />
            <Tooltip title="Edit Quota" placement="top">
              <MDButton
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<EditOutlined />}
                onClick={() => {
                  setIsEdit({
                    disabled: false,
                    edit: true,
                  });
                }}
              >
                Edit
              </MDButton>
            </Tooltip>
          </Stack>
        )}
      </Stack>
      <Grid container spacing={1}>
        <Grid item xs={qualOpen ? 3 : 0} sx={{ display: !qualOpen ? "none" : "" }}>
          <QuestionList
            rows={rows}
            setRows={setRows}
            columns={columns}
            setColumns={setColumns}
            setQualOpen={setQualOpen}
            qualification={qualification}
            setQualification={setQualification}
            addData={addData}
            setProceedData={setProceedData}
          />
        </Grid>
        <Grid item xs={qualOpen ? 9 : 12}>
          <MDBox
            sx={{
              paddingInline: isEdit.disabled ? "" : "10px",
              height: `calc(100vh - 285px)`,
              overflowY: "auto",
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                mb={2}
                mt={1}
                sx={{ paddingTop: "0.5rem" }}
              >
                <img src={QuotaIcon} />
                <Typography fontWeight="600" fontSize="18px" color="primary">
                  Quotas
                </Typography>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  size="small"
                >
                  <Icon color={open ? "primary" : ""}>info</Icon>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <ol
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      marginLeft: "1rem",
                      color: "black",
                    }}
                  >
                    <li>
                      Select Qualification
                      <ul style={{ marginLeft: "1rem" }}>
                        <li>select options</li>
                        <li>configure range</li>
                        <li>open-end qualifications</li>
                      </ul>
                    </li>
                    <li>
                      Proceed to create quotas
                      <ul style={{ marginLeft: "1rem" }}>
                        <li> specify target required</li>
                        <li>quota type</li>
                        <li>target fill type</li>
                      </ul>
                    </li>
                    <li>
                      Proceed to update quota target
                      <ul style={{ marginLeft: "1rem" }}>
                        <li> specify target required</li>
                        <li>click fill targets</li>
                      </ul>
                    </li>
                  </ol>
                </Menu>
              </Stack>
            </Stack>

            <Grid container spacing={2} mb={2} sx={{ display: rows.length <= 0 ? "none" : "" }}>
              <Grid item xs={2}>
                <Label value="Target" required={true} />
                {isEdit.edit || addData ? (
                  <Controller
                    name="target"
                    control={control}
                    rules={{
                      required: "Target is required!",
                    }}
                    render={({ field }) => (
                      <MDInput
                        size="small"
                        {...field}
                        fullWidth
                        disabled={isEdit.disabled}
                        required
                        onChange={(e) => {
                          setValue("target", e.target.value);
                          setWarning(true);
                        }}
                        autoComplete="off"
                        variant="outlined"
                        placeholder="Target"
                        error={errors?.target || quotaSumError || quotaSurveyError ? true : false}
                        helperText={
                          errors?.target
                            ? errors.target.message
                            : quotaSumError
                            ? "Remaining sample target sum should not be greater then Quota target"
                            : quotaSurveyError
                            ? "Quota target cannot be greater than the sample required in the survey"
                            : null
                        }
                      />
                    )}
                  />
                ) : (
                  <Typography fontSize="14px" fontWeight="600">
                    {watch("target")}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Label value="Quota Type" required={true} />
                {isEdit.edit || addData ? (
                  <FormControl fullWidth>
                    <Select
                      sx={{ borderRadius: "0rem !important", height: "36.5px" }}
                      size="small"
                      value={calculationId}
                      onChange={(e) => {
                        setCalculationId(e.target.value);
                        setWarning(true);
                      }}
                    >
                      {calculationList.map((value) => {
                        return <MenuItem value={value.id}>{value.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <Typography fontSize="14px" fontWeight="600">
                    {calculationList.find((val) => Number(val.id) === Number(calculationId))?.name}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Label value="Missing" required={true} />
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  sx={{ marginTop: isEdit.edit || addData ? "0.4rem" : "" }}
                >
                  {addData || proceedData ? watch("target") : watch("missingTarget")}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Label value="Fill Targets" required={true} />
                {isEdit.edit || addData ? (
                  <FormControl fullWidth>
                    <Select
                      sx={{ borderRadius: "0rem !important", height: "36.5px" }}
                      size="small"
                      value={watch("fillTarget")}
                      onChange={(e) => {
                        setValue("fillTarget", e.target.value);
                        setWarning(true);
                      }}
                    >
                      <MenuItem value={1}>Total Target</MenuItem>
                      <MenuItem value={2}>Remaining Target</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <Typography fontSize="14px" fontWeight="600">
                    {Number(watch("fillTarget")) === 1 ? "Total Target" : "Remaining Target"}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <MDButton
                  onClick={() => {
                    let finalPayloadRows = JSON.parse(
                      JSON.stringify(
                        finalRows.map((val) => {
                          return {
                            quota: {
                              completes: val.Completes,
                              id: val.id,
                              isActive: val.Actions,
                              name: val.name ? val.name : "",
                              quotaCpi: val.quotaCpi ? val.quotaCpi : 0,
                              sampleNumber: val.Quota,
                              sampleNumber: val.Remaining,
                              starts: val.Starts,
                              conditions: val.conditions,
                            },
                            surveyId: Number(id),
                          };
                        })
                      )
                    );
                    let localRows = [...finalPayloadRows];
                    let oldRows = finalPayloadRows.filter((val) => !val.quota.isActive);
                    if (Number(watch("fillTarget")) === 1) {
                      localRows = fillTargets(
                        finalPayloadRows.filter((val) => val.quota.isActive),
                        watch("target")
                      );
                    } else {
                      localRows = fillTargets(
                        finalPayloadRows.filter((val) => val.quota.isActive),
                        watch("missingTarget")
                      );
                    }
                    let newRow = oldRows.concat(localRows);
                    setRows(JSON.parse(JSON.stringify(newRow)));
                  }}
                  color="info"
                  size="small"
                  variant="contained"
                  sx={{ marginTop: "1.50rem", display: isEdit.edit || addData ? "" : "none" }}
                >
                  Fill Targets
                </MDButton>
              </Grid>
            </Grid>
            {rows.length > 0 ? (
              <Stack spacing={1} sx={{ marginTop: isEdit.disabled ? "20px" : "30px" }}>
                <Stack spacing={1} direction="row" alignItems="center">
                  <img src={QuotaListIcon} />
                  <Typography fontWeight="600" fontSize="18px" color="primary">
                    Quota List
                  </Typography>
                </Stack>
                {!refreshed ? (
                  <QuotaOverview
                    columns={columns}
                    rows={rows}
                    setRows={setRows}
                    setFinalRows={setFinalRows}
                    addData={addData}
                    isEdit={isEdit}
                    QuotaActiveDeactive={QuotaActiveDeactive}
                    qualification={qualification}
                    QuotaGroupActiveDeactive={QuotaGroupActiveDeactive}
                    proceedData={proceedData}
                    setWarning={setWarning}
                    calculationId={calculationId}
                  />
                ) : (
                  ""
                )}
              </Stack>
            ) : (
              <MDBox
                fontSize="15px"
                fontWeight="500"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={5}
              >
                There are no quotas yet, please select qualifications & options to create quotas !
              </MDBox>
            )}
          </MDBox>
        </Grid>
      </Grid>
    </form>
  );
};

export default NestedQuota;
