import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useForm, Controller } from "react-hook-form";
import MinMax from "comps/Cards/InputFields";
import QuestionBox from "comps/Cards/Checkbox";
import { useSnackbar } from "notistack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QualificationList from "./components/qualificationList";
import AnswerText from "comps/Cards/ansText";
import CustomQualification from "./components/customQualification";
import MDTypography from "components/MDTypography";
import Conditions from "./components/Conditions";
import UploadIcon from '@mui/icons-material/Upload';
import * as XLSX from "xlsx";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function QualificationSurvey() {
  let { setIsEdit, langId, isEdit } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();

  const [showDropdown, setShowDropdown] = useState(false);
  const [qualificationQuestions, setQualificationQuestions] = useState([]);
  const [qualificationQuestionList, setQualificationQuestionList] = useState([]);
  const [qualificaionId, setQualificaionId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [quesLoading, setQuesLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [height, setHeight] = useState(false);
  const [submitQualification, setSubmitQualification] = useState(true);
  const [qualificationError, setQualificaionError] = useState(false);
  const [customQualificationOpen, setCustomQualificationOpen] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [ids, setIds] = useState({ qualificationId: null, qualificationQuestionId: null });
  const [customId, setCustomId] = useState(null);
  const [conditionsHide, setConditionsHide] = useState(true);
  const [addCondition, setAddCondition] = useState(false);
  const [newConditionPayload, setNewConditionPayload] = useState(null);
  const [fileUpload, setFileUpload] = useState("");
  const [zipCodeText, setZipCodeText] = useState("");

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      questionStatus: "standard",
      searchStatus: null,
      file: "",
    },
  });
  let file = watch("file");

  let questionStatus = watch("questionStatus");
  let searchStatus = watch("searchStatus");

  const ShowCustomQualification = () => {
    setCustomQualificationOpen(true);
  };
  const CloseCustomQualification = () => {
    setCustomQualificationOpen(false);
  };

  useEffect(() => { 
    console.log(searchStatus);
  }, [searchStatus]);

  useEffect(() => {
    if (searchStatus) {
      setIds({
        qualificationId: searchStatus.id,
        qualificationQuestionId: searchStatus?.qualificationQuestion?.qualificationQuestionId,
      });
    }
  }, [searchStatus]);

  useEffect(() => {
    if (ids && ids?.qualificationId && ids?.qualificationQuestionId) {
      getAnswers();
    }
  }, [ids.qualificationId]);

  const getAnswers = async () => {
    setIsLoading(true);
    try {
      let response = await TLinkApi.get(
        `/qualifications/${langId}/${ids.qualificationId}/${ids?.qualificationQuestionId}`
      );
      setAnswers(response);
      setZipCodeText(response?.qualificationQuestion?.answers[0]?.qualificationAnswerDesc);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (answers && answers?.qualificationQuestion?.answers) {
      let payload = { ...searchStatus };
      if (payload.qualificationTypeId === 1 || payload.qualificationTypeId === 2) {
        payload.options = answers.qualificationQuestion.answers?.map((answer) => {
          return {
            id: answer.id,
            label: answer.qualificationAnswerDesc,
          };
        });
        payload.selectedAnswers = [];
      } else if (payload.qualificationTypeId === 3) {
        payload.text = "text";
      } else if (payload.qualificationTypeId === 4) {
        payload.selectedRange = [
          {
            min: "1",
            max: "100",
          },
        ];
      }
      setValue("searchStatus", payload);
    }
  }, [answers]);

  const getQualificationList = async (query) => {
    setQuesLoading(true);
    try {
      let data = await TLinkApi.get(
        `/qualifications/survey/${langId}/${
          questionStatus === "standard"
            ? 1
            : questionStatus === "custom"
            ? 2
            : questionStatus === "trap"
            ? 3
            : 4
        }?query=${query ? query : ""}`
      );
      let tempQualificationQuestionList = data
        .filter((qualification) => {
          if (
            qualification?.qualificationQuestion === null ||
            qualificaionId.includes(parseInt(qualification.id))
          ) {
            return false;
          } else {
            return true;
          }
        })
        .map((qualification) => {
          if (qualification.qualificationTypeId === 1 || qualification.qualificationTypeId === 2) {
            qualification.options = qualification.qualificationQuestion.answers?.map((answer) => {
              return {
                id: answer.id,
                label: answer.qualificationAnswerDesc,
              };
            });
            qualification.selectedAnswers = [];
          } else if (qualification.qualificationTypeId === 3) {
            qualification.text = "Text";
          } else if (qualification.qualificationTypeId === 4) {
            qualification.selectedRange = [
              {
                min: "1",
                max: "100",
              },
            ];
          }
          return qualification;
        });
      setQualificationQuestionList(tempQualificationQuestionList);
      setQuesLoading(false);
    } catch (e) {
      setQuesLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (id) getQualificationQuestions();
  }, [id]);

  useEffect(() => {
    if (langId !== "" || qualificaionId.length > 0) {
      if ((query !== undefined || query !== "") && query.length > 2) {
        getQualificationList(query);
      } else {
        getQualificationList(query);
      }
    }
  }, [langId, qualificaionId, query, questionStatus]);

  const handleFile = async (value) => {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
    });
    const zipCode = jsonData.join();
    setZipCodeText(zipCode);
  };

  useEffect(() => {
    if (fileUpload) {
      handleFile(fileUpload);
    }
  }, [fileUpload]);

  const getQualificationQuestions = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`survey/${id}/qualification/conditions`);
      let qualificationIds = result?.qualifications.map(
        (qualification) => qualification.qualificationId
      );
      if (qualificationIds.length !== qualificaionId.length) {
        setQualificaionId(qualificationIds);
      }
      if (result?.qualifications) {
        setIsEdit({
          disabled: true,
          edit: false,
        });
      } else {
        setIsEdit({
          disabled: false,
          edit: true,
        });
      }
      let data = result?.qualifications?.map((question) => {
        if (question.qualificationTypeId === 4) {
          if (question.qualificationTypeId === 4) {
            let ranges = question.answerText.map((value) => {
              let rangeValue = value.split("-");
              let payload = {
                min: parseInt(rangeValue[0]),
                max: parseInt(rangeValue[1]),
              };
              return payload;
            });
            question.range = ranges;
            question.selectedRange = ranges;
          }
        } else if (question.qualificationTypeId === 3) {
          let tempSplitValue = question.answerText;
          question.range = tempSplitValue;
          question.text = tempSplitValue;
        }
        return question;
      });

      setQualificationQuestions(data.sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder)));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsEdit({
        disabled: false,
        edit: true,
      });
      console.log(e);
    }
  };
  const handleCheck = () => {
    if (searchStatus) {
      if (searchStatus.qualificationTypeId === 1 || searchStatus.qualificationTypeId === 2) {
        return <QuestionBox question={searchStatus} updateQuestion={setValue} />;
      } else if (searchStatus.qualificationTypeId === 4) {
        return <MinMax question={searchStatus} updateQuestion={setValue} />;
      } else {
        return (
          <AnswerText
            question={searchStatus}
            updateQuestion={setValue}
            zipCodes={zipCodeText}
            setZipCodeText={setZipCodeText}
          />
        );
      }
    }
  };

  const save = async () => {
    let fileData = "";
    if (file) {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        defval: "",
      });
      fileData = jsonData.join();
    }
    if (!qualificationError) {
      let data = {};
      if (searchStatus.qualificationTypeId === 1 || searchStatus.qualificationTypeId === 2) {
        data = {
          qualificationId: searchStatus.id,
          isActive: true,
          answerId: searchStatus.selectedAnswers,
          qualificationTypeId: searchStatus.qualificationTypeId,
          sortOrder: qualificationQuestions.length + 1,
        };
      } else if (searchStatus.qualificationTypeId === 4) {
        let range = searchStatus?.selectedRange?.map((value) => {
          return `${value.min}-${value.max}`;
        });
        if (range.length === searchStatus.selectedRange.length) {
          data = {
            qualificationId: searchStatus.id,
            answerText: range,
            isActive: true,
            qualificationTypeId: searchStatus.qualificationTypeId,
            sortOrder: qualificationQuestions.length + 1,
          };
        }
      } else {
        data = {
          qualificationId: searchStatus.id,
          answerText: [`${zipCodeText}`],
          isActive: true,
          qualificationTypeId: searchStatus.qualificationTypeId,
          sortOrder: qualificationQuestions.length + 1,
        };
      }

      let payload = {
        qualifications: data,
        surveyId: id,
      };

      try {
        await TLinkApi.post("/survey/qualification/conditions", payload);
        enqueueSnackbar("Created", {
          variant: "success",
        });
        setValue("searchStatus", null);
        setShowDropdown(!showDropdown);
        getQualificationQuestions();
        getQualificationList(query);
        setSubmitQualification(false);
      } catch (e) {
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (searchStatus?.selectedAnswers?.length <= 0) {
      setQualificaionError(true);
    } else if (searchStatus?.text === "") {
      setQualificaionError(true);
    } else if (searchStatus?.selectedRange?.length > 0) {
      let error = searchStatus?.selectedRange?.some(
        (value) =>
          value.min === "" ||
          value.max === "" ||
          Number(value.min) > Number(value.max) ||
          Number(value.min) < 0 ||
          Number(value.max) > 100
      );
      setQualificaionError(error);
    } else {
      setQualificaionError(false);
    }
  }, [searchStatus]);

  useEffect(() => {
    if (customId) {
      let data = qualificationQuestionList.find((val) => Number(val.id) === Number(customId));
      if (data) {
        setValue(
          "questionStatus",
          data.qualificationCategoryTypeId == 1
            ? "standard"
            : data.qualificationCategoryTypeId == 2
            ? "custom"
            : data.qualificationCategoryTypeId == 3
            ? "trap"
            : "openEndedScreener"
        );
        setValue("searchStatus", data);
        setHeight(true);
      }
    }
  }, [customId]);

  useEffect(() => {
    if (!addCondition) {
      setNewConditionPayload(null);
    }
  }, [addCondition]);

  const onDragEnd = async (result) => {
    const { destination } = result;
    if (!destination) {
      return;
    }
    const reorderedSections = Array.from(qualificationQuestions);
    const [moved] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(
      Number(result && result.destination && result?.destination?.index),
      0,
      moved
    );

    const updatedAnswers = reorderedSections.map((qualification, index) => ({
      ...qualification,
      sortOrder: index + 1,
    }));
    await setQualificationQuestions(updatedAnswers);
    QuestionSortOrder(updatedAnswers);
  };

  const QuestionSortOrder = async (updatedAnswers) => {
    setIsLoading(true);
    let payload = updatedAnswers.map((val) => {
      return {
        qualificationId: val.qualificationId,
        sortOrder: val.sortOrder,
      };
    });
    try {
      await TLinkApi.patch(`/survey/${id}/qualification/conditions`, payload);
      await getQualificationQuestions();
      enqueueSnackbar("Qualification sort successfully", {
        variant: "success",
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Oopss! somthig went wrong", {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <MDBox>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom="0.5rem"
          sx={{ borderBottom: "1px solid rgb(228, 228, 228)" }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Tooltip title="Survey Qualifications" placement="top">
              <MDButton
                variant={!conditionsHide ? "outlined" : "contained"}
                size="small"
                color="primary"
                onClick={() => {
                  setConditionsHide(true);
                }}
              >
                Qualifications
              </MDButton>
            </Tooltip>
            <Tooltip title="Terminate Condition" placement="top">
              <MDButton
                variant={conditionsHide ? "outlined" : "contained"}
                size="small"
                color="primary"
                onClick={() => {
                  setConditionsHide(false);
                }}
              >
                Conditions
              </MDButton>
            </Tooltip>
          </Stack>
          <MDBox display="flex" alignItems="center" justifyContent="flex-end" gap="10px">
            <Tooltip
              title={
                conditionsHide ? "Add New Custom Qualification" : "Add New Terminate Condition"
              }
              placement="top"
            >
              <MDButton
                disabled={addCondition}
                onClick={() => {
                  if (conditionsHide) {
                    ShowCustomQualification();
                    setValue("questionStatus", "custom");
                  } else {
                    setAddCondition(true);
                    let payload = { title: "Condition title", qualifications: [], id: -1 };
                    setNewConditionPayload(payload);
                  }
                }}
                variant="contained"
                color="secondary"
                size="small"
              >
                {conditionsHide ? "+ Custom Qualification" : "+ Condition"}
              </MDButton>
            </Tooltip>
          </MDBox>
        </Stack>
        {conditionsHide ? (
          <form>
            <MDBox
              bgColor="white"
              style={{
                height: `calc(100vh - 292px)`,
                overflowY: "auto",
                paddingBottom: "1rem",
              }}
            >
              <MDBox position="sticky" top="0" zIndex={5}>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    gap="5px"
                    style={{
                      background: "#f0f0f0",
                      height: "47px",
                      fontSize: "14px",
                      fontWeight: "500",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
                    }}
                  >
                    <MDBox
                      width="15%"
                      px={1}
                      color="black"
                      sx={{
                        width: {
                          xs: "10%",
                          md: "20%",
                          lg: "15%",
                        },
                        fontSize: { xs: "10px", md: "12px", lg: "15px" },
                      }}
                      style={{
                        borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                      }}
                    >
                      Question Type
                    </MDBox>
                    <MDBox
                      width="72%"
                      px={1}
                      color="black"
                      sx={{
                        width: { xs: "40%", md: "50%", lg: "72%" },
                        fontSize: { xs: "10px", md: "12px", lg: "15px" },
                      }}
                      style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
                    >
                      Question
                    </MDBox>
                    <MDBox
                      color="black"
                      style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        width: { xs: "20%", md: "20%", lg: "10%" },
                      }}
                    >
                      Action
                    </MDBox>
                    <MDBox
                      display="block"
                      sx={{ width: { xs: "10%", md: "10%", lg: "3%" } }}
                      style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
                    ></MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="qualificationId">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {qualificationQuestions.map((value, index) => (
                        <Draggable
                          key={String(value.qualificationId)}
                          draggableId={String(value.qualificationId)}
                          index={index}
                        >
                          {(provided) => (
                            <QualificationList
                              key={index}
                              val={value}
                              index={index}
                              getQualificationQuestions={getQualificationQuestions}
                              questionStatus={questionStatus}
                              qualificationQuestionList={qualificationQuestionList}
                              isEdit={isEdit}
                              setQualificationQuestions={setQualificationQuestions}
                              qualificationQuestions={qualificationQuestions}
                              setIsEdit={setIsEdit}
                              provided={provided}
                            />
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <MDBox mt={3} style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}>
                <MDBox
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  gap="5px"
                  style={{
                    height: "47px",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  <MDBox
                    width="15%"
                    px={1}
                    sx={{
                      width: {
                        xs: "10%",
                        md: "20%",
                        lg: "15%",
                      },
                    }}
                    style={{
                      borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                      borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                    }}
                  >
                    <Controller
                      name="questionStatus"
                      control={control}
                      rules={{
                        required: "Question Status is required!",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <FormControl
                          fullWidth
                          required
                          error={errors?.questionStatus ? true : false}
                          size="small"
                        >
                          <Select
                            value={value}
                            onChange={onChange}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            placeholder="Language List"
                            sx={{
                              height: "36.5px",

                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #D9D9D9",
                                borderRadius: "0px",
                              },
                              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #D9D9D9",
                                borderRadius: "0px",
                              },
                              "& :hover .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #D9D9D9",
                                borderRadius: "0px",
                              },
                            }}
                          >
                            <MenuItem value="standard">Standard</MenuItem>
                            <MenuItem value="custom">Custom</MenuItem>
                            <MenuItem value="trap">Trap</MenuItem>
                            <MenuItem value="openEndedScreener">Open Ended Screener</MenuItem>
                          </Select>
                          {errors?.questionStatus && (
                            <FormHelperText>
                              {errors?.questionStatus ? errors.questionStatus.message : null}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </MDBox>
                  <MDBox
                    px={1}
                    width="72%"
                    sx={{
                      width: { xs: "40%", md: "50%", lg: "72%" },
                    }}
                  >
                    <Controller
                      name="searchStatus"
                      control={control}
                      rules={{
                        required: "Question Status is required!",
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={qualificationQuestionList}
                          getOptionLabel={(option) => option?.qualificationQuestion?.questionText}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          value={value}
                          onChange={(e, value) => {
                            onChange(JSON.parse(JSON.stringify(value)));
                            setHeight(true);
                          }}
                          loading={quesLoading}
                          onInputChange={(_, val) => {
                            if (val !== undefined) {
                              setQuery(val);
                            } else {
                              setQuery("");
                            }
                          }}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option?.qualificationQuestion?.questionText}
                              </li>
                            );
                          }}
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "1.2rem",
                            },

                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #D9D9D9",
                              borderRadius: "0px",
                            },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Question List" size="small" />
                          )}
                        />
                      )}
                    />
                  </MDBox>
                  <MDBox
                    width="10%"
                    style={{
                      borderRight: "1px solid rgba(0, 0, 0, 0.06)",
                      borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
                    }}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      width: { xs: "20%", md: "20%", lg: "10%" },
                    }}
                  >
                    <Switch size="small" color="secondary" disabled checked={submitQualification} />
                  </MDBox>
                  <MDBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: { xs: "10%", md: "10%", lg: "3%" } }}
                    style={{ borderRight: "1px solid rgba(0, 0, 0, 0.06)" }}
                  >
                    <IconButton
                      sx={{ transform: height === true ? "rotate(180deg)" : "" }}
                      onClick={() => {
                        if (height === false) {
                          setHeight(true);
                        } else {
                          setHeight(false);
                        }
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </MDBox>
                </MDBox>
                {searchStatus !== null && (
                  <MDBox
                    style={{ display: height === false && "none" }}
                    bgColor="#E6F7FD"
                    padding="1.5rem"
                  >
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="10px"
                    >
                      <MDTypography
                        fontWeight="regular"
                        color="dark"
                        sx={{
                          fontSize: "16px",
                          fontWeight: 400,
                          width: "80%",
                          overflowWrap: "break-word",
                        }}
                      >
                        {searchStatus?.qualificationQuestion?.questionText}
                      </MDTypography>

                      <MDBox display="flex" alignItems="center" gap="20px" flexDirection="row">
                        {searchStatus?.qualificationCategoryTypeId == 1 && searchStatus.qualificationTypeId == 3 && (
                          <Grid item xs={12} display="flex" alignItems="center">
                            {/* <Label value="Zip File" /> */}
                            <Controller
                              name="file"
                              control={control}
                              render={({ field: { onChange } }) => (
                                <>
                                  <MDButton
                                    variant="outlined"
                                    color="dark"
                                    fullWidth
                                    size="small"
                                    sx={{
                                      borderColor: "#d2d6da",
                                      height: "36px",
                                      borderRadius: "0rem",
                                      boxShadow: "none !important",
                                      "&:focus:not(:hover)": {
                                        boxShadow: "none !important",
                                      },
                                    }}
                                    htmlFor="files"
                                    onClick={() => {
                                      document.getElementById("files").click();
                                    }}
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between !important"
                                      width="100%"
                                    >
                                      <Tooltip
                                        title="csv, text or xls file is allowed to upload"
                                        placement="top"
                                      >
                                        {/* <span>{file !== "" ? file?.name : "Upload file"}</span> */}
                                        <UploadIcon sx={{ marginLeft: "10px", fontSize: "large" }} />
                                      </Tooltip>
                                    </Stack>
                                  </MDButton>
                                  <input
                                    id="files"
                                    onChange={(e) => {
                                      onChange(e.target.files[0]);
                                      setFileUpload(e.target.files[0]);
                                    }}
                                    required
                                    type="file"
                                    style={{ display: "none" }}
                                    label="File Upload"
                                    autoComplete="off"
                                    accept="text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    variant="outlined"
                                  />
                                  {errors?.file && (
                                    <FormHelperText style={{ color: errors?.file && "red" }}>
                                      {errors?.file ? errors.file.message : null}
                                    </FormHelperText>
                                  )}
                                </>
                              )}
                            />
                          </Grid>
                        )}
                        <MDButton
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => {
                            setValue("searchStatus", null);
                          }}
                        >
                          Cancel
                        </MDButton>
                        <MDButton
                          variant="contained"
                          color="info"
                          size="small"
                          onClick={() => {
                            save();
                          }}
                        >
                          Add
                        </MDButton>
                      </MDBox>
                    </MDBox>

                    {handleCheck()}
                  </MDBox>
                )}
              </MDBox>
            </MDBox>
          </form>
        ) : (
          <Conditions
            setAddCondition={setAddCondition}
            addCondition={addCondition}
            qualificationQuestions={qualificationQuestions}
            getQualificationQuestions={getQualificationQuestions}
            newConditionPayload={newConditionPayload}
          />
        )}
      </MDBox>
      {customQualificationOpen && (
        <CustomQualification
          CloseCustomQualification={CloseCustomQualification}
          langId={langId}
          surveyId={id}
          setValueUpdate={setValue}
          setCustomId={setCustomId}
          getQualificationList={getQualificationList}
        />
      )}
    </>
  );
}

export default QualificationSurvey;
