import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
  TextField,
  Tooltip,
  Checkbox,
  Box,
  Chip,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import SearchInput from "components/searchInput";
import MDTypography from "components/MDTypography";
import GetListOfClients from "hooks/ListOfClients/GetListOfClients";
import GetSurveyStatusList from "hooks/SurveyStatusList/GetSurveyStatusList";
import moment from "moment";
import csvDownload from "json-to-csv-export";
import Colors from "../../../../assets/theme/base/colors";
import GetProjectManagerList from "hooks/ProjectManagerList/GetProjectManagerList";
import GetLanguageList from "hooks/LanguageList/GetLanguageList";
import useTableTheme from "constant/TableTheme";
import { Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import GetSurveyBasicList from "hooks/SurveyBasicList/GetSurveyBasicList";
import { Backdrop, CircularProgress } from "@mui/material";
import { Download, IosShare } from "@mui/icons-material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import TLinkApi from "services/tlink.api";
import { saveAs } from "file-saver";

function SurveyList({ projectId, surveyStatusId }) {
  const navigate = useNavigate();
  const { setSurvey, setIsEdit, project, setProject } = useOutletContext();
  const [searchClient, setSearchClient] = useState("");
  const [searchClientList, setSearchClientList] = useState([]);
  const { clients } = GetListOfClients();
  const { surveyStatusList } = GetSurveyStatusList();
  const [statusSearch, setStatusSearch] = useState("");
  const [searchStatusList, setSearchStatusList] = useState([]);

  const { projectManager, setManagerSearch, managerSearch } = GetProjectManagerList();
  const { language } = GetLanguageList();

  const [filterLanguage, setFilterLanguage] = useState([]);
  const [languageSearch, setLanguageSearch] = useState("");
  const [filtersLength, setFiltersLength] = useState({
    languageLength: 0,
    clientLength: 0,
    projectLength: 0,
    statusLength: 0,
  });

  const [sorting, setSorting] = useState([]);
  const { textColor, info } = Colors;

  const tableTheme = useTableTheme();
  let { id } = useParams();

  const {
    surveys,
    getSurveys,
    isLoading,
    totalLength,
    setPage,
    setSearch,
    search,
    setEntriesPerPage,
    languageFilter,
    setLanguageFilter,
    projectMangerFilter,
    setProjectMangerFilter,
    clientFilter,
    setClientFilter,
    filter,
    setFilter,
    dummyPage,
    setSortBy,
    setSortOrder,
    onload,
    setOnload,
    manual,
    setManual,
  } = GetSurveyBasicList(projectId ? projectId : "", filtersLength);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

  useEffect(() => {
    setProject(projectId);
  }, [projectId]);

  useEffect(() => {
    let payload = { ...filtersLength };
    payload.languageLength = language.length;
    setFiltersLength(payload);
  }, [language]);

  useEffect(() => {
    let payload = { ...filtersLength };
    payload.clientLength = clients.length;
    setFiltersLength(payload);
  }, [clients]);

  useEffect(() => {
    let payload = { ...filtersLength };
    payload.projectLength = projectManager.length;
    setFiltersLength(payload);
  }, [projectManager]);

  useEffect(() => {
    let payload = { ...filtersLength };
    payload.statusLength = surveyStatusList.length;
    setFiltersLength(payload);
  }, [surveyStatusList]);

  useEffect(() => {
    if (surveyStatusId && projectId) {
      if (!onload) {
        setFilter([String(surveyStatusId)]);
      }
      setOnload(false);
    }
  }, [surveyStatusId]);

  useEffect(() => {
    setData(surveys);
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);

    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        setSortBy(sorting[0].id);
        setSortOrder("asc");
      } else {
        setSortBy(sorting[0].id);
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [surveys, pagination, sorting]);

  useEffect(() => {
    setSearchClientList(clients);
  }, [clients]);

  useEffect(() => {
    let searchData = clients?.filter((data) => {
      const searchString = typeof searchClient === "string" ? searchClient : "";
      return data?.name.toLowerCase().includes(searchString.toLowerCase());
    });
    setSearchClientList(searchData);
  }, [searchClient]);

  useEffect(() => {
    if (languageSearch === "") {
      setFilterLanguage(language);
    } else {
      setFilterLanguage(
        language?.filter((item) => item.name.toLowerCase().includes(languageSearch.toLowerCase()))
      );
    }
  }, [languageSearch, language]);

  useEffect(() => {
    if (Array.isArray(surveyStatusList)) {
      let searchStatusData = surveyStatusList.filter((data) => {
        return data.name.toLowerCase().includes(statusSearch.toLowerCase());
      });
      setSearchStatusList(searchStatusData);
    } else {
      setSearchStatusList([]);
    }
  }, [statusSearch, surveyStatusList]);

  let columns = [
    {
      accessorKey: "id",
      header: "Id",
      size: 50,
      Cell: ({ cell }) => {
        const id = cell.getValue();
        return id ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsEdit({
                disabled: true,
                edit: false,
              });
              navigate(`/surveys/${cell.getValue()}`);
            }}
          >
            {cell.getValue()}
          </div>
        ) : null;
      },
    },

    {
      accessorKey: "surveyName",
      header: "Survey Name",
      size: 50,
      enableSorting: false,
      headerAlign: "center",
    },

    {
      accessorKey: "clientName",
      header: "Client Name",
      size: 50,
      enableSorting: false,
      headerAlign: "center",
    },

    {
      accessorKey: "sampleRequired",
      header: "Samp. Req.",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "countryCode",
      header: "Country",
      size: 50,
      enableSorting: false,
      headerAlign: "center",
    },
    {
      accessorKey: "loi",
      header: "Loi(mins)",
      size: 50,
      headerAlign: "center",
    },

    {
      accessorKey: "ir",
      header: "IR(%)",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "cpi",
      header: "CPI($)",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        const cpi = parseFloat(cell.getValue());
        if (cpi <= 0) {
          return cpi;
        } else {
          const roundedCpi = Math.round(cpi * 100) / 100;
          return roundedCpi ? roundedCpi.toFixed(2) : null;
        }
      },
    },
    {
      accessorKey: "starts",
      header: "Starts",
      size: 50,
      headerAlign: "center",
    },

    {
      accessorKey: "completes",
      header: "Completes",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "epc",
      header: "EPC",
      size: 50,
      headerAlign: "center",
      Cell: ({ renderedCellValue, row }) => {
        return row
          ? parseFloat(
              row?.original?.starts <= 0 ? 0 : row?.original?.revenue / row?.original?.starts
            ).toFixed(2)
          : null;
      },
    },
    {
      accessorKey: "conversion",
      header: "Conv(%)",
      size: 50,
      headerAlign: "center",
      Cell: ({ renderedCellValue, row }) => {
        if (row) {
          return (
            <div style={{ width: "20px" }}>
              {Math.ceil(
                row?.original?.starts <= 0
                  ? 0
                  : (row?.original?.completes / row?.original?.starts) * 100
              )}
            </div>
          );
        } else {
          return null;
        }
      },
    },
    {
      accessorKey: "clientConversion",
      header: "Client Conv(%)",
      size: 50,
      headerAlign: "center",
    },
    {
      accessorKey: "revenue",
      header: "Revenue",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        const revenue = parseFloat(cell.getValue());
        if (revenue) {
          const roundedRevenue = Math.round(revenue * 100) / 100;
          return roundedRevenue.toFixed(2);
        } else {
          return "NA";
        }
      },
    },

    {
      accessorKey: "createdAt",
      header: "Created at",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        if (!cell.getValue()) {
          return <div>NA</div>;
        } else {
          const createdAtTime = moment(cell.getValue());
          const formattedDateTime = createdAtTime.format("Do MMM YYYY, h:mm:ss a");
          const truncatedName =
            formattedDateTime?.length > 8
              ? formattedDateTime?.substring(0, 8) + "..."
              : formattedDateTime;
          return data ? (
            <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
              <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
            </Tooltip>
          ) : null;
        }
      },
    },

    {
      accessorKey: "lastCompleted",
      header: "Last Complete",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        if (!cell.getValue()) {
          return <div>NA</div>;
        } else {
          const lastCompletedTime = moment(cell.getValue());
          const formattedDateTime = lastCompletedTime.format("Do MMM YYYY, h:mm:ss a");
          const truncatedName =
            formattedDateTime?.length > 8
              ? formattedDateTime?.substring(0, 8) + "..."
              : formattedDateTime;
          return data ? (
            <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
              <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
            </Tooltip>
          ) : null;
        }
      },
    },

    {
      accessorKey: "surveyStatusName",
      header: "Status",
      size: 50,
      headerAlign: "center",
    },
  ];

  let selectStyle = {
    height: "35px",
    fontSize: "15px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/surveys/${row.original.id}`, { state: { id: projectId } });
      },
      sx: {
        cursor: "pointer",
      },
      component: Link,
      to: `/surveys/${row.original.id}`,
      style: { textDecoration: "none", color: "inherit" },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        maxHeight: projectId ? "calc(100vh - 485px)" : "calc(100vh - 400px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });

  const getSupplierLink = async () => {
    try {
      // Fetch supplier links using the IDs
      const response = await TLinkApi.get(`/projects/${projectId}/report`);
      const supplierData = response.data;

      // Convert data to CSV format
      const headers = [
        "Survey Id",
        "Supplier Id",
        "Supplier Name",
        "Project Id",
        "CPI",
        "LOI",
        "IR",
        "Link",
        "Test_Link",
      ];
      const csvContent =
        headers.join(",") +
        "\n" +
        supplierData
          .map((row) =>
            [
              row["Survey Id"],
              row["Supplier Id"],
              row["Supplier Name"],
              row["Project Id"],
              row["CPI"],
              row["LOI"],
              row["IR"],
              `"${row["Link"]}"`, // Enclose links in quotes for proper CSV formatting
              `"${row["Test_Link"]}"`,
            ].join(",")
          )
          .join("\n");

      // Create a Blob and trigger download
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      saveAs(blob, `SupplierLinks_${projectId}.csv`);
    } catch (error) {
      console.error("Error fetching supplier links:", error);
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox pb={projectId ? 0 : 1} width="100%">
        <Grid container spacing={2} pb={projectId ? 0 : 1} display="flex" flexDirection="row">
          <Grid
            px={projectId ? 0 : 2}
            item
            xs={6}
            md={6}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-end", md: "center" },
            }}
            gap="15px"
          >
            <MDTypography fontSize="25px" color="black" ml={projectId ? "" : "1%"}>
              Surveys
            </MDTypography>
          </Grid>
          {projectId && (
            <Grid
              px={projectId ? 0 : 2}
              item
              xs={6}
              md={6}
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-end", md: "center" },
              }}
              gap="15px"
            >
              <MDButton
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  navigate("/surveys/new", { state: { id: projectId } });
                  setSurvey({});
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + Survey
              </MDButton>
            </Grid>
          )}
        </Grid>

        <hr
          style={{
            border: `1px solid ${textColor.main}`,
            width: "100%",
            display: projectId ? "none" : "",
          }}
        />
        {!projectId && (
          <Grid container mt={2}>
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-end", md: "center" },
              }}
              gap="15px"
            >
              <MDTypography color="black" ml={projectId ? "" : "1%"}>
                Survey List
              </MDTypography>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              display="flex"
              justifyContent="flex-end"
              flexDirection="row"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-end", md: "center" },
              }}
              gap="15px"
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={manual}
                    onChange={(e) => {
                      setOnload(false);
                      setManual(e.target.checked);
                    }}
                  />
                }
                label={"Manual"}
              />
              <MDButton
                variant="contained"
                color="info"
                size="small"
                onClick={() => {
                  navigate("/surveys/new");
                  setSurvey({});
                  setIsEdit({
                    disabled: false,
                    edit: false,
                  });
                }}
              >
                + Survey
              </MDButton>
            </Grid>
          </Grid>
        )}
        <MDBox display="flex" justifyContent="center" mt={2}>
          <Box sx={{ width: "100%", boxSizing: "border-box" }}>
            <Grid container spacing={2}>
              <Grid item xs={2.3}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    label: {
                      fontWeight: "40",
                    },
                  }}
                >
                  <InputLabel>Market</InputLabel>
                  <Select
                    multiple
                    value={Array.isArray(languageFilter) ? languageFilter : []}
                    label="Language"
                    MenuProps={{
                      autoFocus: false,
                    }}
                    sx={selectStyle}
                    renderValue={(selected) => (
                      <div>
                        {selected.slice(0, 1).map((option, index) => (
                          <span>
                            {
                              filterLanguage.find((item) => Number(item.id) === Number(option))
                                ?.name
                            }
                          </span>
                        ))}
                        {selected?.length > 1 && (
                          <Chip
                            sx={{ backgroundColor: "white" }}
                            label={`+${selected?.length - 1} more`}
                            style={{
                              height: "20px",
                              marginTop: "3px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  >
                    <ListSubheader sx={{ marginBottom: "0.5rem" }}>
                      <TextField
                        sx={{
                          position: "sticky",
                          marginTop: "-16px",
                          paddingTop: "1rem",
                          zIndex: 1,
                          background: "white",
                        }}
                        size="small"
                        autoFocus
                        value={languageSearch}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setLanguageSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                        InputProps={{
                          endAdornment: languageSearch && (
                            <InputAdornment
                              position="end"
                              onClick={() => setLanguageSearch("")}
                              style={{ cursor: "pointer" }}
                            >
                              <ClearIcon sx={{ fontSize: "small" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListSubheader>
                    <MenuItem
                      onClick={() => {
                        if (
                          Array.isArray(languageFilter) &&
                          languageFilter.length === filterLanguage?.length
                        ) {
                          setOnload(false);
                          setTimeout(() => {
                            setLanguageFilter([]);
                          }, 200);
                        } else {
                          setOnload(false);
                          setTimeout(() => {
                            setLanguageFilter(filterLanguage?.map((value) => value.id));
                          }, 200);
                        }
                      }}
                    >
                      <Checkbox
                        checked={
                          Array.isArray(languageFilter) &&
                          languageFilter.length === filterLanguage?.length &&
                          filterLanguage?.length > 0
                        }
                      />
                      Select All
                    </MenuItem>

                    {Array.isArray(filterLanguage) &&
                      filterLanguage.map((value, i) => (
                        <MenuItem
                          key={i}
                          value={value.id}
                          onClick={() => {
                            if (languageFilter?.includes(value.id)) {
                              let payload = languageFilter?.filter((val) => val !== value.id);
                              setOnload(false);
                              setTimeout(() => {
                                setLanguageFilter(payload);
                              }, 200);
                            } else {
                              let payload = [...languageFilter];
                              payload.push(value.id);
                              setOnload(false);
                              setTimeout(() => {
                                setLanguageFilter(payload);
                              }, 200);
                            }
                          }}
                        >
                          <Checkbox
                            checked={
                              Array.isArray(languageFilter) && languageFilter.includes(value.id)
                            }
                          />
                          {value.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={projectId ? 0 : 2.3} sx={{ display: projectId ? "none" : "" }}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    label: {
                      fontWeight: "400",
                    },
                    display: projectId ? "none" : "",
                  }}
                >
                  <InputLabel>Clients</InputLabel>
                  <Select
                    multiple
                    value={Array.isArray(clientFilter) ? clientFilter : []}
                    label="Clients"
                    MenuProps={{
                      autoFocus: false,
                    }}
                    sx={selectStyle}
                    renderValue={(selected) => (
                      <div>
                        {selected.slice(0, 1).map((option, index) => (
                          <span>
                            {
                              searchClientList.find((item) => Number(item.id) === Number(option))
                                ?.name
                            }
                          </span>
                        ))}
                        {selected?.length > 1 && (
                          <Chip
                            sx={{ backgroundColor: "white" }}
                            label={`+${selected?.length - 1} more`}
                            style={{
                              height: "20px",
                              marginTop: "3px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  >
                    <ListSubheader sx={{ marginBottom: "0.5rem" }}>
                      <TextField
                        sx={{
                          position: "sticky",
                          marginTop: "-16px",
                          paddingTop: "1rem",
                          zIndex: 1,
                          background: "white",
                        }}
                        size="small"
                        autoFocus
                        value={searchClient}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setSearchClient(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                        InputProps={{
                          endAdornment: searchClient && (
                            <InputAdornment
                              position="end"
                              onClick={() => setSearchClient("")}
                              style={{ cursor: "pointer" }}
                            >
                              <ClearIcon sx={{ fontSize: "small" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListSubheader>
                    <MenuItem
                      onClick={() => {
                        if (
                          Array.isArray(clientFilter) &&
                          clientFilter.length === searchClientList?.length
                        ) {
                          setOnload(false);
                          setTimeout(() => {
                            setClientFilter([]);
                          }, 200);
                        } else {
                          setOnload(false);
                          setTimeout(() => {
                            setClientFilter(searchClientList?.map((value) => value.id));
                          }, 200);
                        }
                      }}
                    >
                      <Checkbox
                        checked={
                          Array.isArray(clientFilter) &&
                          clientFilter.length === searchClientList?.length &&
                          searchClientList?.length > 0
                        }
                      />
                      Select All
                    </MenuItem>

                    {Array.isArray(searchClientList) &&
                      searchClientList.map((value, i) => (
                        <MenuItem
                          value={value.id}
                          key={i}
                          onClick={() => {
                            if (clientFilter.includes(value.id)) {
                              let payload = clientFilter?.filter((val) => val !== value.id);
                              setOnload(false);
                              setTimeout(() => {
                                setClientFilter(payload);
                              }, 200);
                            } else {
                              let payload = [...clientFilter];
                              payload.push(value.id);
                              setOnload(false);
                              setTimeout(() => {
                                setClientFilter(payload);
                              }, 200);
                            }
                          }}
                        >
                          <Checkbox
                            checked={Array.isArray(clientFilter) && clientFilter.includes(value.id)}
                          />
                          {value.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={projectId ? 0 : 2.2} sx={{ display: projectId ? "none" : "" }}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    label: {
                      fontWeight: "400",
                    },
                    display: projectId ? "none" : "",
                  }}
                >
                  <InputLabel>Project Manager</InputLabel>
                  <Select
                    multiple
                    value={Array.isArray(projectMangerFilter) ? projectMangerFilter : []}
                    sx={selectStyle}
                    label="Project Manager"
                    MenuProps={{
                      autoFocus: false,
                    }}
                    onClose={() => setManagerSearch("")}
                    renderValue={(selected) => (
                      <div>
                        {selected.slice(0, 1).map((option, index) => (
                          <span>
                            {
                              projectManager.find((item) => Number(item.id) === Number(option))
                                ?.fullName
                            }
                          </span>
                        ))}
                        {selected?.length > 1 && (
                          <Chip
                            sx={{ backgroundColor: "white" }}
                            label={`+${selected?.length - 1} more`}
                            style={{
                              height: "20px",
                              marginTop: "3px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  >
                    <ListSubheader sx={{ marginBottom: "0.5rem" }}>
                      <TextField
                        sx={{
                          position: "sticky",
                          marginTop: "-16px",
                          paddingTop: "1rem",
                          zIndex: 1,
                          background: "white",
                        }}
                        size="small"
                        autoFocus
                        required
                        value={managerSearch}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setManagerSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                        InputProps={{
                          endAdornment: managerSearch && (
                            <InputAdornment
                              position="end"
                              onClick={() => setManagerSearch("")}
                              style={{ cursor: "pointer" }}
                            >
                              <ClearIcon sx={{ fontSize: "small" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListSubheader>
                    <MenuItem
                      onClick={() => {
                        if (
                          Array.isArray(projectMangerFilter) &&
                          projectMangerFilter.length === projectManager.length
                        ) {
                          setOnload(false);
                          setTimeout(() => {
                            setProjectMangerFilter([]);
                          }, 200);
                        } else {
                          setOnload(false);
                          setTimeout(() => {
                            setProjectMangerFilter(projectManager?.map((value) => value.id));
                          }, 200);
                        }
                      }}
                    >
                      <Checkbox
                        checked={
                          Array.isArray(projectMangerFilter) &&
                          projectMangerFilter.length === projectManager.length
                        }
                      />
                      Select All
                    </MenuItem>
                    {Array.isArray(projectManager) &&
                      projectManager.map((value) => (
                        <MenuItem
                          key={value.id}
                          value={value.id}
                          onClick={() => {
                            if (projectMangerFilter.includes(value.id)) {
                              let payload = projectMangerFilter?.filter((val) => val !== value.id);
                              setOnload(false);
                              setTimeout(() => {
                                setProjectMangerFilter(payload);
                              }, 200);
                            } else {
                              let payload = [...projectMangerFilter];
                              payload.push(value.id);
                              setOnload(false);
                              setTimeout(() => {
                                setProjectMangerFilter(payload);
                              }, 200);
                            }
                          }}
                        >
                          <Checkbox
                            checked={
                              Array.isArray(projectMangerFilter) &&
                              projectMangerFilter.includes(value.id)
                            }
                          />
                          {value.fullName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                    multiple
                    value={Array.isArray(filter) ? filter : []}
                    label="Status"
                    MenuProps={{
                      autoFocus: false,
                    }}
                    sx={selectStyle}
                    onClose={() => setStatusSearch("")}
                    renderValue={(selected) => (
                      <div>
                        {selected.slice(0, 1).map((option, index) => (
                          <span>
                            {
                              searchStatusList.find((item) => Number(item.id) === Number(option))
                                ?.name
                            }
                          </span>
                        ))}
                        {selected?.length > 1 && (
                          <Chip
                            sx={{ backgroundColor: "white" }}
                            label={`+${selected?.length - 1} more`}
                            style={{
                              height: "20px",
                              marginTop: "3px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  >
                    <ListSubheader sx={{ marginBottom: "0.5rem" }}>
                      <TextField
                        sx={{
                          position: "sticky",
                          marginTop: "-16px",
                          paddingTop: "1rem",
                          zIndex: 1,
                          background: "white",
                        }}
                        size="small"
                        autoFocus
                        required
                        value={statusSearch}
                        placeholder="Type to search..."
                        fullWidth
                        onChange={(e) => setStatusSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            e.stopPropagation();
                          }
                        }}
                        InputProps={{
                          endAdornment: statusSearch && (
                            <InputAdornment
                              position="end"
                              onClick={() => setStatusSearch("")}
                              style={{ cursor: "pointer" }}
                            >
                              <ClearIcon sx={{ fontSize: "small" }} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </ListSubheader>
                    <MenuItem
                      onClick={() => {
                        if (Array.isArray(filter) && filter.length === searchStatusList.length) {
                          setOnload(false);
                          setTimeout(() => {
                            setFilter([]);
                          }, 200);
                        } else {
                          setOnload(false);
                          setTimeout(() => {
                            setFilter(searchStatusList?.map((value) => String(value.id)));
                          }, 200);
                        }
                      }}
                    >
                      <Checkbox
                        checked={
                          Array.isArray(filter) &&
                          filter.length === surveyStatusList.length &&
                          surveyStatusList.length > 0
                        }
                      />
                      Select All
                    </MenuItem>
                    {searchStatusList?.map((value, i) => (
                      <MenuItem
                        key={i}
                        value={value.id}
                        onClick={() => {
                          if (Array.isArray(filter) && filter.includes(String(value.id))) {
                            let payload = filter?.filter((val) => String(val) !== String(value.id));
                            setOnload(false);
                            setTimeout(() => {
                              setFilter(payload);
                            }, 200);
                          } else {
                            let payload = [...filter];
                            payload.push(String(value.id));
                            setOnload(false);
                            setTimeout(() => {
                              setFilter(payload);
                            }, 200);
                          }
                        }}
                      >
                        <Checkbox
                          checked={Array.isArray(filter) && filter?.includes(String(value.id))}
                        />
                        {value.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={projectId ? 1.5 : 1}>
                <MDButton
                  variant="contained"
                  color="info"
                  size="small"
                  onClick={() => {
                    getSurveys();
                  }}
                >
                  Apply Filter
                </MDButton>
              </Grid>
              <Grid item xs={projectId ? 6.2 : 2.2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "8px",
                  }}
                >
                  <SearchInput setSearch={setSearch} search={search} />
                  <Tooltip title="Export" placement="bottom">
                    <IconButton
                      variant="contained"
                      color="white"
                      sx={{
                        backgroundColor: `${info.main} !important`,
                        borderRadius: "4px !important",
                      }}
                      size="small"
                      onClick={() => {
                        const dataToConvert = {
                          data: surveys,
                          filename: `SurveyPage${dummyPage}`,
                          delimiter: ",",
                        };
                        csvDownload(dataToConvert);
                      }}
                    >
                      <IosShare sx={{ fontSize: "1.1rem", color: "white" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Export Supplier Links" placement="bottom">
                    <IconButton
                      variant="contained"
                      color="white"
                      sx={{
                        backgroundColor: `${info.main} !important`,
                        borderRadius: "4px !important",
                        display: projectId ? "" : "none",
                      }}
                      size="small"
                      onClick={getSupplierLink}
                    >
                      <Download sx={{ fontSize: "1.1rem", color: "white" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                table={table}
                filterVariant="multi-select"
                style={{
                  width: "100%",
                }}
              />
            </ThemeProvider>
          </Box>
        </MDBox>
      </MDBox>
    </>
  );
}

export default SurveyList;
