import GetReportSubStatusList from "hooks/ReportSubStatus/GetReportSubStatusList";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetReportHistoryList = () => {
  const [reportHistory, setReportHistory] = useState([]);
  const [reportHistoryMap, setReportHistoryMap] = useState();
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");

  const GetReportHistory = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(
        `/reports/history?search=${search}&page=${
          page + 1
        }&limit=${entriesPerPage}&sortOrder=${sortOrder}&sortBy=${sortBy}`
      );
      setReportHistory(data.data.reportData);
      setTotalLength(data.data.totallength);
      setIsLoading(false);
      let reportHistoryMap = [];
      data?.data?.reportData.map((value) => {
        reportHistoryMap[value.id] = value.name;
        return true;
      });
      setReportHistoryMap(reportHistoryMap);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    GetReportHistory();
  }, [page, search, entriesPerPage, sortBy, sortOrder]);

  return {
    reportHistory,
    setReportHistory,
    reportHistoryMap,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
  };
};

export default GetReportHistoryList;
