import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Backdrop, CircularProgress, Grid, Stack, ThemeProvider, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import colors from "assets/theme/base/colors";
import useTableTheme from "constant/TableTheme";
import moment from "moment";
import GetListOfReportHistory from "hooks/GetReportHistoryList/getReportHistoryList";
import { Download } from "@mui/icons-material";
import FileDownloadOffIcon from "@mui/icons-material/FileDownloadOff";
const ReportHistory = () => {
  let { textColor } = colors;
  const tableTheme = useTableTheme();
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [filteredOptions, setFilteredOptions] = useState({});
  const [searchQuery, setSearchQuery] = useState({});
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [isReportSelected, setIsReportSelected] = useState(false);
  const [debouncedSearches, setDebouncedSearches] = useState({});
  const {
    reportHistory,
    setReportHistory,
    reportHistoryMap,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
  } = GetListOfReportHistory();

  useEffect(() => {
    setData(reportHistory);
  }, [reportHistory]);

  useEffect(() => {
    setPage(pagination.pageIndex);
    setEntriesPerPage(pagination.pageSize);
  }, [pagination]);

  useEffect(() => {
    if (sorting.length === 1) {
      if (sorting[0].desc === false) {
        if (sorting[0].id === "jobId") {
          setSortBy("id");
        } else {
          setSortBy(sorting[0].id);
        }
        setSortOrder("asc");
      } else {
        if (sorting[0].id === "jobId") {
          setSortBy("id");
        } else {
          setSortBy(sorting[0].id);
        }
        setSortOrder("desc");
      }
    } else if (sorting.length === 0) {
      setSortBy("id");
      setSortOrder("asc");
    }
  }, [reportHistory, sorting]);

  let columns = [
    {
      accessorKey: "jobId",
      header: "Job Id",
      size: 50,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      Cell: ({ cell }) => <div>{cell.getValue()}</div>,
    },
    {
      accessorKey: "reportName",
      header: "Report Name",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const reportName = cell.getValue();
        const truncatedName =
          reportName?.length > 15 ? reportName.substring(0, 15) + "..." : reportName;
        return (
          <Tooltip title={cell.getValue()} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "statusName",
      header: "Status Name",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const statusName = cell.getValue();
        const truncatedName =
          statusName?.length > 15 ? statusName.substring(0, 15) + "..." : statusName;
        return (
          <Tooltip title={statusName} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "startDate",
      header: "Start Date",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return (
          <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
            <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
          </Tooltip>
        );
      },
    },

    {
      accessorKey: "endDate",
      header: "End Date",
      size: 50,
      headerAlign: "center",
      Cell: ({ cell }) => {
        return (
          <Tooltip title={moment(cell.getValue()).format("YYYY-MM-DD, h:mm:ss a")}>
            <div>{moment(cell.getValue()).format("YYYY-MM-DD")}</div>
          </Tooltip>
        );
      },
    },

    {
      accessorKey: "emailSentStatus",
      header: "Email Sent",
      size: 50,
      headerAlign: "center",
      // Cell: ({ cell }) => {
      //   return <div>{cell.getValue() ? "Sent" : "Not Sent"}</div>;
      // },
    },

    {
      accessorKey: "userName",
      header: "UserName",
      align: "center",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const userName = cell.getValue();
        const truncatedName = userName?.length > 15 ? userName.substring(0, 15) + "..." : userName;
        return (
          <Tooltip title={userName} placement="bottom-start">
            <div>{truncatedName}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "reasonOfFailure",
      header: "Reason of Failure",
      align: "center",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const reasonOfFailure = cell.getValue();
        const truncatedName =
          reasonOfFailure?.length > 15 ? reasonOfFailure.substring(0, 15) + "..." : reasonOfFailure;
        return (
          <Tooltip title={reasonOfFailure || "No reason provided"} placement="top">
            <div>{truncatedName || "NA"}</div>
          </Tooltip>
        );
      },
    },
    {
      accessorKey: "filePathUrl",
      header: "Download",
      align: "center",
      size: 50,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      headerAlign: "center",
      Cell: ({ cell }) => {
        const fileUrl = cell.getValue();

        return fileUrl ? (
          <a href={fileUrl} download="">
            <Download sx={{ color: "#59A65E" }} />
          </a>
        ) : (
          <FileDownloadOffIcon sx={{ color: "#59A65E", cursor: "not-allowed" }} />
        );
      },
    },
  ];
  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    manualPagination: true,
    rowCount: totalLength,
    onPaginationChange: setPagination,
    state: { density: "compact", pagination, sorting },
    enableColumnPinning: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    manualSorting: true,
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],
    },
    muiTableBodyRowProps: ({ row }) => ({
      style: { textDecoration: "none", color: "inherit" },
    }),
    onSortingChange: setSorting,
    muiTableContainerProps: {
      sx: {
        maxHeight: "calc(100vh - 380px)",
        overflowY: "auto",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        width: "100%",
        borderRadius: "0",
        backgroundColor: "#fff !important",
        zIndex: 500,
      },
    },
    renderEmptyRowsFallback: () => (
      <Stack display="flex" alignItems="center" justifyContent="center" fontSize="14px" p={5}>
        No records to display
      </Stack>
    ),
  });
  return (
    <MDBox>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
        <MDTypography fontSize="25px" color="black">
          Reports History List
        </MDTypography>
      </Stack>

      <Grid container>
        <Grid item xs={12} md={12}>
          <hr style={{ border: `1px solid ${textColor.main}`, width: "100%" }} />
        </Grid>
      </Grid>

        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            table={table}
            style={{
              width: "100%",
            }}
          />
        </ThemeProvider>
    </MDBox>
  );
};

export default ReportHistory;
