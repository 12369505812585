import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
} from "@mui/material";
import MDInput from "components/MDInput";
import Label from "components/InputLabel";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";

const CloneModal = ({ open, handleClose, defaultLanguageId, language, languageMap }) => {
  const { id } = useParams();
  let { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      projectName: "",
      // languageId: "",
    },
  });
  const save = async (data) => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.post(`/projects/all/${id}`, data);
      enqueueSnackbar("Project clone successfully", {
        variant: "success",
      });
      handleClose();
      reset({
        projectName: "",
        // languageId: "",
      });
      if (result?.data?.new_project_id) {
        navigate(`/projects/${result?.data?.new_project_id}`);
      } else {
        navigate(`/projects`);
      }
      setAlertOpen(false);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (open) {
  //     setValue("languageId", defaultLanguageId);
  //   }
  // }, [open]);

  // useEffect(() => {
  //   if (alertOpen) {
  //     alert(
  //       "You will have to set qualification options/quota/terminate conditions explicitly if you choose to clone a survey for a different market"
  //     );
  //   }
  // }, [alertOpen]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(save)} noValidate>
        <DialogTitle>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <span>Clone Project</span>
            <Stack direction="row" alignItems="center" spacing={2}>
              <MDButton
                onClick={() => {
                  handleClose();
                  setAlertOpen(false);
                  reset({
                    projectName: "",
                    // languageId: "",
                  });
                }}
                variant="contained"
                color="secondary"
                size="small"
              >
                cancel
              </MDButton>
              <MDButton type="submit" variant="contained" color="info" size="small">
                Save
              </MDButton>
            </Stack>
          </Stack>
          <hr style={{ marginTop: "0.5rem", background: "#f5f5f5" }} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12}>
              <Label value="Project Title" required={true} />
              <Controller
                name="projectName"
                control={control}
                rules={{
                  required: "Project Name is required!",
                }}
                render={({ field }) => (
                  <MDInput
                    size="small"
                    {...field}
                    fullWidth
                    required
                    autoComplete="off"
                    variant="outlined"
                    error={errors?.projectName ? true : false}
                    helperText={errors?.projectName ? errors.projectName.message : null}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Label value="Market" required={true} />
              <Controller
                name="languageId"
                control={control}
                rules={{
                  required: "Market Field is required!",
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth required error={errors?.languageId ? true : false}>
                    <Autocomplete
                      value={value}
                      onChange={(_event, newValue) => {
                        onChange(newValue?.id);
                        if (newValue && newValue?.id !== defaultLanguageId) {
                          setAlertOpen(true);
                        }
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          minHeight: "20px",
                        },
                      }}
                      isOptionEqualToValue={(option, value) => option?.id == value}
                      options={language}
                      getOptionLabel={(option) =>
                        option.name
                          ? option.name
                          : languageMap[watch("languageId")]
                          ? languageMap[watch("languageId")]
                          : ""
                      }
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option?.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <MDInput {...params} variant="outlined" size="small" required />
                      )}
                    />
                    {errors?.languageId && (
                      <FormHelperText>
                        {errors?.languageId ? errors.languageId.message : null}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default CloneModal;
